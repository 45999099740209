import React from 'react';
import { Routes, Route } from 'react-router-dom'
import TeacherHome from "../pages/teacher/teacher-home/teacher-home.component";
import TeacherCourses from "../pages/teacher/teacher-courses/teacher-courses.component";
import TeacherCourseDetails from "../pages/teacher/teacher-course-details/teacher-course-details.component";
import TeacherPayments from "../pages/teacher/teacher-payments/teacher-payments.component";

const TeacherRoutes = () => {
    return(
        <Routes>
            <Route path="/" element={<TeacherHome/>}></Route>
            <Route path="/courses" element={<TeacherCourses/>}></Route>
            <Route path="/course-details" element={<TeacherCourseDetails/>}></Route>
            <Route path="/payments" element={<TeacherPayments/>}></Route>
        </Routes>
    )
};

export default TeacherRoutes;