import React from "react";
import "./course-card.style.scss";
import {Link} from "react-router-dom";

const CourseCard = ({ course, btn='Enroll Now' }) => {
  return (
      <div
          className="courses-card ">
          <div className="card">
              <img alt="Thumbnail"
                   className="card-course-thumbnail"
                   src={course.image}
              />
              <div className="card-body p-2">
                  <div className="card-course-name text-start ps-1 pb-0 mb-1">{course.title}
                  </div>
                  <div className="card-course-author text-start ps-1">By {course.author}</div>
                  <div className="course-rating pt-1">
                      <span className="course-rating-value ps-1">{course.rating}</span>
                      <span className="rating-stars">
                              <svg fill="none" height="11" viewBox="0 0 12 11" width="12"
                                   xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd"
                                                                            d="M5.25578 0.468285C5.52655 -0.156095 6.45009 -0.156095 6.72087 0.468285L7.97925 3.37047L11.2455 3.62207C11.949 3.67599 12.2343 4.51777 11.6982 4.95837L9.20983 7.00312L9.96957 10.0601C10.1334 10.7187 9.38692 11.2387 8.78493 10.8862L5.98832 9.24787L3.19172 10.8862C2.58973 11.2387 1.84328 10.7181 2.00707 10.0601L2.76682 7.00312L0.278462 4.95837C-0.257649 4.51777 0.027632 3.67599 0.731165 3.62207L3.9974 3.37047L5.25578 0.468285Z"
                                                                            fill="#A48534" fill-rule="evenodd"/></svg>
                              <svg fill="none" height="11" viewBox="0 0 12 11" width="12"
                                   xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd"
                                                                            d="M5.25578 0.468285C5.52655 -0.156095 6.45009 -0.156095 6.72087 0.468285L7.97925 3.37047L11.2455 3.62207C11.949 3.67599 12.2343 4.51777 11.6982 4.95837L9.20983 7.00312L9.96957 10.0601C10.1334 10.7187 9.38692 11.2387 8.78493 10.8862L5.98832 9.24787L3.19172 10.8862C2.58973 11.2387 1.84328 10.7181 2.00707 10.0601L2.76682 7.00312L0.278462 4.95837C-0.257649 4.51777 0.027632 3.67599 0.731165 3.62207L3.9974 3.37047L5.25578 0.468285Z"
                                                                            fill="#A48534" fill-rule="evenodd"/></svg>
                              <svg fill="none" height="11" viewBox="0 0 12 11" width="12"
                                   xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd"
                                                                            d="M5.25578 0.468285C5.52655 -0.156095 6.45009 -0.156095 6.72087 0.468285L7.97925 3.37047L11.2455 3.62207C11.949 3.67599 12.2343 4.51777 11.6982 4.95837L9.20983 7.00312L9.96957 10.0601C10.1334 10.7187 9.38692 11.2387 8.78493 10.8862L5.98832 9.24787L3.19172 10.8862C2.58973 11.2387 1.84328 10.7181 2.00707 10.0601L2.76682 7.00312L0.278462 4.95837C-0.257649 4.51777 0.027632 3.67599 0.731165 3.62207L3.9974 3.37047L5.25578 0.468285Z"
                                                                            fill="#A48534" fill-rule="evenodd"/></svg>
                              <svg fill="none" height="11" viewBox="0 0 12 11" width="12"
                                   xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd"
                                                                            d="M5.25578 0.468285C5.52655 -0.156095 6.45009 -0.156095 6.72087 0.468285L7.97925 3.37047L11.2455 3.62207C11.949 3.67599 12.2343 4.51777 11.6982 4.95837L9.20983 7.00312L9.96957 10.0601C10.1334 10.7187 9.38692 11.2387 8.78493 10.8862L5.98832 9.24787L3.19172 10.8862C2.58973 11.2387 1.84328 10.7181 2.00707 10.0601L2.76682 7.00312L0.278462 4.95837C-0.257649 4.51777 0.027632 3.67599 0.731165 3.62207L3.9974 3.37047L5.25578 0.468285Z"
                                                                            fill="#A48534" fill-rule="evenodd"/></svg>
                              <svg fill="none" height="11" viewBox="0 0 12 11" width="12"
                                   xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd"
                                                                            d="M5.25578 0.468285C5.52655 -0.156095 6.45009 -0.156095 6.72087 0.468285L7.97925 3.37047L11.2455 3.62207C11.949 3.67599 12.2343 4.51777 11.6982 4.95837L9.20983 7.00312L9.96957 10.0601C10.1334 10.7187 9.38692 11.2387 8.78493 10.8862L5.98832 9.24787L3.19172 10.8862C2.58973 11.2387 1.84328 10.7181 2.00707 10.0601L2.76682 7.00312L0.278462 4.95837C-0.257649 4.51777 0.027632 3.67599 0.731165 3.62207L3.9974 3.37047L5.25578 0.468285Z"
                                                                            fill="#A48534" fill-rule="evenodd"/></svg>
                            </span>
                      <span className="course-rating-count pt-1">({course.reviews})</span>
                  </div>
                  <div className="course-price text-start ps-1 pb-2 pt-1">
                      ${course.price}
                  </div>

                  {
                      course?.status ?
                      <div className='course-card-buttons col-12 mb-1'>
                          <button className={`btn ${course?.status === 'paid' ? 'card-btn-inactive' :'card-btn-enroll'}`}>{btn}</button>
                          <button className={`btn card-btn-view`}><Link to='/course-details'>View</Link></button>
                      </div>
                          :
                              <Link
                                  to="/course-details"
                                  className="btn col-12 mb-1 card-btn-enroll"
                              >
                                  {btn}
                              </Link>
                  }
              </div>
          </div>
      </div>
  );
};

export default CourseCard;
