import './calendar-schedule.style.scss';
import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import arrowRight from '../../../assets/arrow-right-black.svg'
import arrowLeft from '../../../assets/arrow-left.svg'
const CalendarScheduleComponent = () => {
    const [date, setDate] = useState(new Date());

    // Example schedule data
    const schedule = [
        { date: "2025-01-01", day: "Wednesday", time: "5pm - 9pm", active: true },
        { date: "2025-01-02", day: "Thursday", time: "5pm - 9pm", active: false },
        { date: "2025-01-08", day: "Wednesday", time: "5pm - 9pm", active: false },
        { date: "2025-01-09", day: "Thursday", time: "5pm - 9pm", active: false },
        { date: "2025-01-10", day: "Wednesday", time: "5pm - 9pm", active: false },
    ];

    return (
        <div className="calendar-schedule">
            {/* Calendar Section */}
            <div className="calendar-container">
                <h2>Calendar</h2>
                <Calendar
                    onChange={setDate}
                    value={date}
                    tileClassName={({ date }) =>
                        schedule.some(event => event.date === date.toISOString().split("T")[0])
                            ? "highlight"
                            : ""
                    }
                    prev2Label={null}
                    next2Label={null}
                    prevLabel={<img src={arrowLeft} alt=""/>}
                    nextLabel={<img src={arrowRight} alt=""/>}
                    formatShortWeekday={(locale, date) =>
                        date.toLocaleDateString("en-GB", { weekday: "short" }).slice(0, 2)
                    }
                />
            </div>

            {/* Schedule Section */}
            <div className="schedule-container">
                <h2>Your Detailed Schedule</h2>
                <div className='schedule-wrap'>
                    <div className='schedule-header'>
                        <img src={arrowLeft} alt=""/>
                        <span>JavaScript for Beginners</span>
                        <img src={arrowRight} alt=""/>
                    </div>
                    <table>
                        <tbody>
                        {schedule.map((item, index) => (
                            <tr key={index}>
                                <td>{new Date(item.date).toLocaleDateString("en-GB", {
                                    day: "numeric",
                                    month: "short"
                                })}</td>
                                <td>{item.day}</td>
                                <td>{item.time}</td>
                                <td>
                                    <button className={item.active ? "join-btn active" : "join-btn"}
                                            disabled={!item.active}>
                                        Join Now
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
export default CalendarScheduleComponent;