import "bootstrap/dist/css/bootstrap.min.css";
import './sign-in.style.scss';
import React, { useState } from "react";
import eyeOn from '../../../assets/eye-on.svg';
import eyeOff from '../../../assets/eye-off.svg';
import { useNavigate } from "react-router-dom";
import SuccessModal from '../success-modal/success-modal.component';
import ErrorModal from "../error-modal/error-modal.component";
import {useAuth} from "../../../context/auth-context";


const SignInComponent = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [errorHeader, setErrorHeader] = useState('');
    const [successHeader, setSuccessHeader] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const validateForm = () => {
        const { email, password } = formData;
        var re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        if (!email || !password) {
            setErrorHeader("Required fields are empty");
            setErrorMessage("Please fill in the required fields!");
            setShowErrorModal(true);
            return false;
        }

        if (password.length < 8) {
            setErrorHeader("Password too short");
            setErrorMessage("Password must be at least 8 characters long!");
            setShowErrorModal(true);
            return false;
        }

        if (re.test(email) === false) {
            console.log("31");
            setErrorHeader("Invalid email address");
            setErrorMessage("Please enter a valid email address!");
            setShowErrorModal(true);
            return false;
        }

        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        if (!validateForm()) return;

        setShowSuccessModal(true);
        setSuccessHeader("Login Successful");
        setSuccessMessage("Congrats, you have successfully logged in!");
        setTimeout(() => {
            login('student');
            navigate('/');
        }, 2000);

        // try {
        //     const response = await fetch('/api/signin', {
        //         method: 'POST',
        //         headers: { 'Content-Type': 'application/json' },
        //         body: JSON.stringify({ ...formData })
        //     });

        //     const data = await response.json();

        //     if (response.status === 409) {
        //         setErrorMessage("This email is already registered. Try logging in instead.");
        //         setShowErrorModal(true); // Show modal for error
        //     } else if (response.ok) {
        //         setSuccessMessage("Sign-up successful. Please log in.");
        //         setTimeout(() => navigate('/login'), 2000);
        //     } else {
        //         setErrorMessage("Something went wrong. Please try again later.");
        //         setShowErrorModal(true); // Show modal for error
        //     }
        // } catch (error) {
        //     // setErrorMessage("Something went wrong. Please try again later.");
        //     // setShowErrorModal(true); // Show modal for error
        //     setSuccessMessage("Sign-up successful. Please log in.");
        //     setTimeout(() => navigate('/courses'), 2000);
        // }
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCloseSuccess = () => {
        setShowSuccessModal(false);
        setSuccessMessage('');
    };

    const handleCloseError = () => {
        setShowErrorModal(false);
        setErrorMessage('');
    }

    return (
        <div className="sign-in-container">
            <h2>User Sign In</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email*</label>
                    <input type="text" name="email" placeholder="Email" onChange={handleInputChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password*</label>
                    <div className="sign-in-password-input-wrapper">
                        <input type={showPassword ? 'text' : 'password'} name="password" placeholder="Password" onChange={handleInputChange} />
                        <img src={showPassword ? eyeOn : eyeOff} alt="eye" className="eye-icon" onClick={() => setShowPassword(!showPassword)} />
                    </div>
                </div>
                <div className="sign-in-forgot-password" onClick={() => navigate('/authentication/forgot-password')}>
                    <p>Forgot Password?</p>
                </div>
                <div className="sign-in-button-wrapper">
                    <button type="submit" className="sign-in-submit-button">Sign In</button>
                </div>
            </form>
            {showSuccessModal && <div className="modal-overlay">
                <SuccessModal header={successHeader} content={successMessage} onClose={handleCloseSuccess}/>
            </div>}
            {showErrorModal && <div className="modal-overlay">
                <ErrorModal header={errorHeader} content={errorMessage} onClose={handleCloseError} />
            </div>}
            <div className="sign-in-with-social">
                <div className="sign-in-divider">
                    <hr />
                    <p>Or Sign In with</p>
                    <hr />
                </div>
                <div className="sign-in-social-icons">
                    <a href="https://www.facebook.com"><svg width="56" height="55" viewBox="0 0 56 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.9997 5.729C22.1207 5.729 16.4825 8.02271 12.3255 12.1055C8.16842 16.1884 5.83301 21.7259 5.83301 27.4998C5.83301 33.2738 8.16842 38.8113 12.3255 42.8941C16.4825 46.977 22.1207 49.2707 27.9997 49.2707C33.8786 49.2707 39.5168 46.977 43.6739 42.8941C47.8309 38.8113 50.1663 33.2738 50.1663 27.4998C50.1663 21.7259 47.8309 16.1884 43.6739 12.1055C39.5168 8.02271 33.8786 5.729 27.9997 5.729Z" fill="#039BE5" />
                        <path d="M31.001 33.2706H36.7375L37.6382 27.5471H30.9999V24.419C30.9999 22.0414 31.7909 19.9331 34.0554 19.9331H37.6942V14.9384C37.0549 14.8536 35.7027 14.668 33.1477 14.668C27.8125 14.668 24.6847 17.4352 24.6847 23.7395V27.5471H19.2002V33.2706H24.6847V49.0017C25.7709 49.1621 26.871 49.271 28.0004 49.271C29.0212 49.271 30.0175 49.1793 31.001 49.0487V33.2706Z" fill="white" />
                    </svg></a>

                    <a href="https://www.google.com"><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M43.611 20.083H42V20H24V28H35.303C33.654 32.657 29.223 36 24 36C17.373 36 12 30.627 12 24C12 17.373 17.373 12 24 12C27.059 12 29.842 13.154 31.961 15.039L37.618 9.382C34.046 6.053 29.268 4 24 4C12.955 4 4 12.955 4 24C4 35.045 12.955 44 24 44C35.045 44 44 35.045 44 24C44 22.659 43.862 21.35 43.611 20.083Z" fill="#FFC107" />
                        <path d="M6.30566 14.691L12.8767 19.51C14.6547 15.108 18.9607 12 23.9997 12C27.0587 12 29.8417 13.154 31.9607 15.039L37.6177 9.382C34.0457 6.053 29.2677 4 23.9997 4C16.3177 4 9.65566 8.337 6.30566 14.691Z" fill="#FF3D00" />
                        <path d="M24.0003 44.0002C29.1663 44.0002 33.8603 42.0232 37.4093 38.8082L31.2193 33.5702C29.2113 35.0912 26.7153 36.0002 24.0003 36.0002C18.7983 36.0002 14.3813 32.6832 12.7173 28.0542L6.19531 33.0792C9.50531 39.5562 16.2273 44.0002 24.0003 44.0002Z" fill="#4CAF50" />
                        <path d="M43.611 20.083H42V20H24V28H35.303C34.511 30.237 33.072 32.166 31.216 33.571L31.219 33.569L37.409 38.807C36.971 39.205 44 34 44 24C44 22.659 43.862 21.35 43.611 20.083Z" fill="#1976D2" />
                    </svg></a>

                    <a href="https://x.com/home"><svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M53.3749 15.7952C51.6936 16.5399 49.8852 17.0368 47.9777 17.2719C49.917 16.1205 51.4089 14.2842 52.1091 12.1149C50.2944 13.1785 48.2839 13.9601 46.1451 14.3719C44.4371 12.5609 42.0009 11.4375 39.3017 11.4375C34.1167 11.4375 29.914 15.6033 29.914 20.74C29.914 21.4669 29.9992 22.1748 30.1593 22.8623C22.3589 22.4709 15.4405 18.7626 10.8122 13.1353C9.99882 14.5104 9.54132 16.1205 9.54132 17.8196C9.54132 21.0488 11.1947 23.8917 13.716 25.5666C12.177 25.5196 10.7283 25.0913 9.45999 24.4051C9.45999 24.433 9.45999 24.475 9.45999 24.5144C9.45999 29.0271 12.6968 32.785 16.9871 33.6402C16.203 33.855 15.3744 33.9745 14.5204 33.9745C13.9143 33.9745 13.3233 33.9058 12.7514 33.8029C13.946 37.4909 17.4116 40.1863 21.5177 40.2664C18.305 42.7572 14.2587 44.2491 9.85903 44.2491C9.09907 44.2491 8.35564 44.2059 7.62109 44.117C11.778 46.7489 16.7139 48.2917 22.012 48.2917C39.2763 48.2917 48.7211 34.1143 48.7211 21.8151C48.7211 21.4123 48.7084 21.0107 48.6893 20.6142C50.532 19.3129 52.1206 17.6709 53.3749 15.7952Z" fill="#03A9F4" />
                    </svg></a>

                </div>
            </div>
        </div>
    );
};

export default SignInComponent;