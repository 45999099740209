import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AdminRoutes from './admin-routes';
import TeacherRoutes from './teacher-routes';
import StudentRoutes from './student-routes';
import GuestRoutes from './guest-routes';
import { useAuth } from '../context/auth-context';

const AppRoutes = () => {
    const { user } = useAuth();
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
                {/* Admin Routes */}
                {user === 'admin' && <Route path="/*" element={<AdminRoutes />} />}

                {/* Teacher Routes */}
                {user === 'teacher' && (
                    <Route path="/*" element={<TeacherRoutes />} />
                )}

                {/* Student Routes */}
                {user === 'student' && (
                    <Route path="/*" element={<StudentRoutes />} />
                )}

                {/* Guest Routes */}
                {!user && <Route path="/*" element={<GuestRoutes />} />}

                {/* Fallback for unauthorized access */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;