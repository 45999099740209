import { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import './pagination.style.scss';
const Pagination = ({ totalPages = 18, maxVisible = 5 }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const changePage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const getPageNumbers = () => {
        const pages = [];
        if (totalPages <= maxVisible) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);

            pages.push(1);
            if (startPage > 2) pages.push("...");

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            if (endPage < totalPages - 1) pages.push("...");
            pages.push(totalPages);
        }
        return pages;
    };

    return (
        <div className="pagination-container">
            {/* Previous Button */}
            <button
                className="pagination-btn"
                onClick={() => changePage(currentPage - 1)}
                disabled={currentPage === 1}
            >
                <ChevronLeft size={16} />
            </button>

            {/* Page Numbers */}
            {getPageNumbers().map((page, index) =>
                page === "..." ? (
                    <span key={index} className="pagination-dots">...</span>
                ) : (
                    <button
                        key={index}
                        className={`pagination-btn ${currentPage === page ? "active" : ""}`}
                        onClick={() => changePage(page)}
                    >
                        {page}
                    </button>
                )
            )}

            {/* Next Button */}
            <button
                className="pagination-btn"
                onClick={() => changePage(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                <ChevronRight size={16} />
            </button>
        </div>
    );
};

export default Pagination;
