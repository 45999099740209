import './navigation.style.scss';
import {Link, useNavigate} from "react-router-dom";
import logo from '../../../logo.svg';
import signIn from '../../../assets/sign-in.svg';
import arrowDown from '../../../assets/arrow-down.svg';
import userSvg from '../../../assets/user.svg';
import {useAuth} from "../../../context/auth-context";

const Navigation = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const redirectFunctions = () => {
        navigate('/#how-it-works');
        setTimeout(() => {
            const element = document.getElementById("how-it-works");
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 300);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-container">
            <div className="container-fluid">
                <Link className="navbar-brand d-block d-lg-none" to={"/"}>
                    <img className="navbar-logo" src={logo} height="84" width="203" alt="Logo" />
                </Link>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav navbar-list col">
                        <li className="nav-item d-none d-lg-block">
                            <Link className="navbar-brand" to={"/"}>
                                <img className="navbar-logo" src={logo} height="84" width="203" alt="Logo" />
                            </Link>
                        </li>

                        {!user && (
                            <li className="nav-item">
                                <Link className="nav-link" to="/about-us">About Us</Link>
                            </li>
                        )}

                        {user ? (
                            <li className="nav-item">
                                <Link className="nav-link" to="/">Home</Link>
                            </li>
                        ) : (
                            <li className="nav-item">
                                <button className="nav-link btn btn-link p-0" onClick={redirectFunctions}>
                                    How it works
                                </button>
                            </li>
                        )}

                        <li className="nav-item">
                            <Link className="nav-link" to="/courses">Courses</Link>
                        </li>

                        {user ? (
                            <li className="nav-item">
                                <Link className="nav-link" to="/payments">Payments</Link>
                            </li>
                        ) : (
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact-us">Contact Us</Link>
                            </li>
                        )}

                        <li className={`nav-item ${user ? 'navbar-user' : 'navbar-login'}`}>
                            {user ? (
                                <div className='nav-user-link'>
                                    <span className='nav-user-icon'>
                                        <img src={userSvg} alt="User Icon" />
                                    </span>
                                    <img src={arrowDown} alt="Dropdown Arrow" />
                                </div>
                            ) : (
                                <Link className="login-link" to="/authentication">
                                    <span className="login-text">Log In</span>
                                    <span className="login-icon">
                                        <img src={signIn} alt="Sign In Icon" />
                                    </span>
                                </Link>
                            )}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;
