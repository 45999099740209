import { useState } from "react";
import filter from '../../../assets/filter.svg';
import arrowUp from '../../../assets/arrow-up.svg';
import arrowBottom from '../../../assets/arrow-down.svg';
import './filtering.style.scss';
import star from '../../../assets/star.svg';
import emptyStar from '../../../assets/empty-star.svg';
import checkBox from '../../../assets/checkbox.svg';
import checkedBox from '../../../assets/checked-box.svg';

const FilterComponent = ({ onFilterChange }) => {
    const [expandedSections, setExpandedSections] = useState({});
    const [checkedItems, setCheckedItems] = useState({});

    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const toggleCheckbox = (key) => {
        setCheckedItems(prev => ({
            ...prev,
            [key]: !prev[key]
        }));
    };

    const filterData = {
        "Category": [
            { name: "Programming", count: 215 },
            { name: "Design", count: 232 },
            { name: "Business & Marketing", count: 210 },
            { name: "Academics", count: 190 },
            { name: "Data Science & AI", count: 186 },
            { name: "Creative Arts", count: 160 },
        ],
        "Ratings": [5, 4, 3, 2, 1].map((stars, index) => ({
            name: stars,
            count: [82, 96, 113, 45, 20][index],
            stars
        })),
        "Price": [
            { name: "Free", count: 160 },
            { name: "Paid", count: 300 },
        ],
        "Language": [
            { name: "English", count: 350 },
            { name: "Türkçe", count: 115 },
            { name: "Español", count: 100 },
            { name: "Deutsch", count: 98 },
            { name: "Français", count: 90 },
        ],
        "Course Type": [
            { name: "Group Learning", count: 325 },
            { name: "Individual Learning", count: 270 },
        ]
    };

    return (
        <div className="filter-container">
            <div className="filter-header">
                <img src={filter} className="filter-icon" alt="filter" />
                <span className="filter-title">Filter</span>
            </div>

            {Object.entries(filterData).map(([section, items], sectionIndex) => (
                <div key={sectionIndex} className="filter-section">
                    <div className="filter-section-header" onClick={() => toggleSection(section)}>
                        <div className="filter-section-title">{section}</div>
                        <img src={expandedSections[section] ? arrowUp : arrowBottom} className="filter-arrow-icon" alt="toggle" />
                    </div>
                    {expandedSections[section] && (
                        <div className="filter-options">
                            {items.map((item, index) => (
                                <div key={index} className="filter-option" onClick={() => toggleCheckbox(`${section}-${index}`)}>
                                    <img
                                        src={checkedItems[`${section}-${index}`] ? checkedBox : checkBox}
                                        className="filter-checkbox"
                                        alt="checkbox"
                                    />
                                    <div className="filter-option-text">
                                        {section === "Ratings" ? (
                                            <span className="filter-stars">
                                                {[...Array(item.stars)].map((_, i) => <img key={i} src={star} className="filter-star" alt="star" />)}
                                                {[...Array(5 - item.stars)].map((_, i) => <img key={i} src={emptyStar} className="filter-star" alt="empty star" />)}
                                            </span>
                                        ) : (
                                            <span className="filter-option-name">{item.name}</span>
                                        )}
                                        <span className="filter-option-count">({item.count})</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default FilterComponent;
