import Footer from '../../../components/layout/footer/footer.component';
import Navigation from '../../../components/layout/navigation/navigation.component';
import './about-us.style.scss';
import {Fragment} from "react";


const AboutUs = () =>{
    return(
        <Fragment>
            <Navigation/>
            <section className="about-us-section row">
                <div className="col-1"></div>
                <div className="col-10">
                    <h1 className="about-us-title">ABOUT US</h1>
                    <div className="cards-container">
                        <div className="card" data-title="Founders">
                            <h3>Our Team</h3>
                            <p>"A Group of Antalya Bilim University Graduates United for a Common Goal."</p>
                        </div>
                        <div className="card" data-title="Mission Statement">
                            <h3>Description</h3>
                            <p>“Bringing together, now and forever”</p>
                        </div>
                        <div className="card" data-title="Objectives">
                            <h3>TeachLink Goals</h3>
                            <p>"Accessibility and Security in Education: TeachLink Goals".</p>
                        </div>
                        <div className="card" data-title="Join Us">
                            <h3>Join Us</h3>
                            <p>Let's Redefine Education Together!</p>
                        </div>
                    </div>
                </div>
                <div className="col-1"></div>
            </section>
            <Footer/>
        </Fragment>
    );
}

export default AboutUs;