import { Fragment } from "react";
import clock from '../../../assets/clock.svg';
import sClock from '../../../assets/small-clock.svg';
import './group-card.style.scss';
const GroupCardComponent = () => {
    return (
        <Fragment>
            <div className="group-card">
                <div className="group-title">Group 1</div>
                <div className="group-details">
                    <div className="group-info">
                        <img className="group-icon" src={clock} alt="Clock" />
                        <span className="group-duration">75 Hours</span>
                        <span className="group-price">$49.99</span>
                    </div>
                    <div className="group-meta">
                        <span className="group-start-date">&#8226; Start date: 15th January</span>
                        <span className="group-max-students">&#8226; Max students: 10</span>
                    </div>
                    <div className="group-schedules">
                        <div className="group-schedule">
                            <span className="group-day">Wednesdays</span>
                            <span className="group-time">5pm - 9pm</span>
                            <span className="group-duration-detail">
                                <img className="group-small-icon" src={sClock} alt="Small Clock"/>{" "}4hr
                            </span>
                        </div>
                        <div className="group-schedule">
                            <span className="group-day">Thursdays</span>
                            <span className="group-time">5pm - 9pm</span>
                            <span className="group-duration-detail">
                                <img className="group-small-icon" src={sClock} alt="Small Clock"/>{" "}4hr
                            </span>
                        </div>
                    </div>
                    <div className="group-action">
                        <button className="group-enroll-btn">Enroll Now</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default GroupCardComponent;
