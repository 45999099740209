import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(""); // { role: 'teacher', name: 'John Doe' }

    const login = (userData) => {
        console.log(userData);
        setUser(userData);
    };

    const logout = () => {
        setUser("");
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);