import './course-description.style.scss';
import { useState } from "react";
import star from '../../../assets/star.svg';

const CourseDescriptionComponent = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const descriptionText = "JavaScript for Beginners is designed to introduce you to the fundamentals of JavaScript, the powerful programming language that drives dynamic and interactive elements on websites. As one of the most widely used languages for web development, JavaScript is essential for creating responsive websites, controlling multimedia, and enhancing user interactions. In this course, you will engage in hands-on exercises and real-world examples that provide practical experience with JavaScript. You will start by learning key concepts such as variables, which are used to store data; functions, which allow you to group and reuse code; loops, which enable repetitive tasks to be automated; and events, which help create interactive experiences by responding to user actions. These concepts form the foundation for building more advanced web applications and can be applied to various web technologies. By the end of this course, you will be comfortable writing simple JavaScript programs and will have a strong understanding of how JavaScript fits into the larger context of web development. Whether you are aiming to become a web developer or simply want to learn how to make websites more engaging, this course will provide the knowledge and skills to get started on your journey.\n" +
        "\n" +
        "\n" +
        "\n";

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <section className="course-description">
            <div className="course-header">
                <h1 className="course-title">JavaScript for Beginners</h1>
                <div className="course-content">
                    <div className="course-image-wrapper">
                        <img
                            className="course-image"
                            src={'https://s3-alpha-sig.figma.com/img/15de/88df/d7d1ad735fc2b2565f1ac76c3c30ba8e?Expires=1740960000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=RSdonnREpozDUAo7SHSy1F8SI8ke6Us3OboOMdzUocLGb4VhWq9H29SPainiaTWX~6FfQ1c0F1lI7RJSzkN5IezMaYnn6Iyrh~Acb25cmWIzND~r6sqM41zTavkxFxvflPQVBDX79trH1Iessrt~g9OdGXzMkZy0wObpQiqvlWutZC67vV5qCLMt5YEBD657Gqey7eNAvaIgHXHjb00iMg5xR4bmFCR66J9J3Y2y5YlJuzabkuYahlcG6v-qNrAy1IOiN3XE5QG~Wn4eskfLC-mLRxa4L9jMRLit9VSb2kA1XEGsvavn4fnBdSUcYAa0iiXW8zgEldVayKBKnkmESg__'}
                            alt="JavaScript Course"
                        />
                    </div>
                    <div className="course-info">
                        <span className="course-summary">
                            Learn JavaScript basics to build interactive web features.
                        </span>
                        <span className="course-instructor">By Liam Carter</span>
                        <div className="course-rating">
                            <span className="rating-value">5</span>
                            <span className="rating-stars">
                                {Array.from({length: 5}).map((_, index) => (
                                    <img key={index} className="star-icon" src={star} alt="Star"/>
                                ))}
                            </span>
                            <span className="rating-count">(2500)</span>
                        </div>
                        <div className="course-enrollment">Total Enrollment: 4000</div>
                    </div>
                </div>
            </div>
            <div className="course-details">
                <div className="course-description-header">Course Description</div>
                <p className="course-description-text">
                    {isExpanded ? descriptionText : `${descriptionText.substring(0, 300)}`}
                    {!isExpanded && "... "}
                    <span className="read-more-link" onClick={toggleReadMore}>
                        {isExpanded ? "Read Less" : "Read More"}
                    </span>
                </p>
            </div>
        </section>
    );
}

export default CourseDescriptionComponent;
