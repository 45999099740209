import './reset-password.style.scss';

import React, {Fragment, useState} from "react";
import Footer from "../../../components/layout/footer/footer.component";
import Navigation from "../../../components/layout/navigation/navigation.component";
import eyeOff from "../../../assets/eye-off.svg";
import eyeOn from "../../../assets/eye-on.svg";

const ResetPassword = () =>{
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    return (
        <Fragment>
            <Navigation/>
            <div className="reset-password-container">
                <div className="reset-password-header">
                    <h2 className="reset-password-title">Reset Password</h2>
                </div>

                <div className="reset-password-input-wrapper">
                    <label className="reset-password-label">New Password</label>
                    <div className="reset-password-input-wrapper">
                        <input name="password" className="reset-password-input"
                               type={showPassword ? 'text' : 'password'} placeholder="Create password"
                               />
                        <div className="reset-password-toggle-btn" type="button"
                             onClick={() => setShowPassword(!showPassword)}>
                            <img src={showPassword ? eyeOff : eyeOn} alt="Toggle Password"/>
                        </div>
                    </div>
                </div>
                <div className="reset-password-input-wrapper">
                    <label className="reset-password-label">Confirm Password*</label>
                    <div className="reset-password-input-wrapper">
                        <input name="confirmPassword" className="reset-password-input"
                               type={showConfirmPassword ? 'text' : 'password'} placeholder="Rewrite password"
                               />
                        <div className="reset-password-toggle-btn" type="button"
                             onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            <img src={showConfirmPassword ? eyeOff : eyeOn} alt="Toggle Confirm Password"/>
                        </div>
                    </div>
                </div>


                <div className="reset-password-button-wrapper">
                    <button className="reset-password-submit-button">Continue</button>
                </div>
            </div>
            <Footer/>
        </Fragment>
    );
}
export default ResetPassword;