import React from 'react';
import { Routes, Route } from 'react-router-dom'
import StudentHome from "../pages/student/student-home/student-home.component";
import StudentCourses from "../pages/student/student-courses/student-courses.component";
import StudentCourseDetails from "../pages/student/student-course-details/student-course-details.component";
import StudentPayments from "../pages/student/student-payments/student-payments.componet";
import AboutUs from "../pages/guest/about-us/about-us.component";

const StudentRoutes = () => {
    return(
        <Routes>
            <Route path="/" element={<StudentHome/>}></Route>
            <Route path="/courses" element={<StudentCourses/>}></Route>
            <Route path="/course-details" element={<StudentCourseDetails/>}></Route>
            <Route path="/payments" element={<StudentPayments/>}></Route>
            <Route path="/about-us" element={<AboutUs/>}></Route>
        </Routes>
    )
};

export default StudentRoutes;