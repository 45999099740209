import './student-course-details.style.scss';
import React, {Fragment} from "react";
import Navigation from "../../../components/layout/navigation/navigation.component";
import CourseDescriptionComponent from "../../../components/common/course-description/course-description.component";
import clock from '../../../assets/small-clock.svg';
const StudentCourseDetails = () =>{
    return(
        <Fragment>
            <Navigation></Navigation>
            <CourseDescriptionComponent></CourseDescriptionComponent>
            <div className="student-group-table-container">
                <h2>My Group</h2>
                <table>
                    <thead>
                    <tr>
                        <th colSpan='100%'><span>Group 2</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className={`course-active`}>Wednesdays</td>
                        <td>5pm - 9pm</td>
                        <td className="s-group-time"><img height='23px' width='23px' src={clock} alt=""/> <span>4 Hours</span>
                        </td>
                        <td>
                            <button className={`btn`}>Join Now</button>
                        </td>
                    </tr>
                    <tr className="alt-row">
                        <td>Thursdays</td>
                        <td>5pm - 9pm</td>
                        <td className="s-group-time"><img height='23px' width='23px' src={clock} alt=""/> <span>4 Hours</span>
                        </td>
                        <td>
                            <button className={`btn btn-inactive`}>Join Now</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="student-exam-table-container">
                <h2>Exams</h2>
                <table>
                    <thead>
                    <tr>
                        <th>Exam Name</th>
                        <th>Date</th>
                        <th>Duration</th>
                        <th>Grade</th>
                        <th>Exam File</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Quiz 1</td>
                        <td>2.09.2023</td>
                        <td>1 Hour</td>
                        <td>95/100</td>
                        <td><button className='btn'>Download</button></td>
                    </tr>
                    <tr className="alt-row">
                        <td>Quiz 2</td>
                        <td>4.10.2023</td>
                        <td>1 Hour</td>
                        <td>85/100</td>
                        <td>
                            <button className='btn'>Download</button>
                        </td>

                    </tr>
                    <tr>
                        <td className="exam-active">Quiz 3</td>
                        <td>24.08.2023</td>
                        <td>1 Hour</td>
                        <td className='status'>Pending</td>
                        <td>
                            <button className='btn btn-submit'>Submit</button>
                        </td>
                    </tr>
                    <tr className="alt-row ">
                    <td>Midterm</td>
                        <td>14.11.2023</td>
                        <td>2 Hours</td>
                        <td className='status'>Pending</td>
                        <td>
                            <button className='btn btn-inactive'>Download</button>
                        </td>
                    </tr>
                    <tr className="">
                        <td>Final</td>
                        <td>24.01.2024</td>
                        <td>2 Hours</td>
                        <td className='status'>Pending</td>
                        <td>
                            <button className='btn btn-inactive'>Download</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}
export default StudentCourseDetails;