import './authentication.style.scss';
import SignUpComponent from "../../../components/common/sign-up/sign-up.component";
import {Fragment, useState} from "react";
import Footer from "../../../components/layout/footer/footer.component";
import Navigation from "../../../components/layout/navigation/navigation.component";
import TabsComponent from "../../../components/common/tabs/tabs.component";
import SignInComponent from "../../../components/common/sign-in/sign-in.component";

const Authentication = () =>{
    const [activeTab, setActiveTab] = useState("signin");

    const tabs = [
        { key: "signin", label: "Sign In" },
        { key: "signup", label: "Sign Up" },
    ];

    return(
        <Fragment>
            <Navigation></Navigation>
            <div className="authentication-container">
                <TabsComponent tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />
            </div>
            {activeTab === "signup" && <SignUpComponent />}
            {activeTab === "signin" && <SignInComponent />}
            <Footer></Footer>
        </Fragment>
    )
};
export default Authentication;