import './footer.style.scss';
import logo from '../../../logo.svg';
import React, { Fragment } from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <Fragment>
            <footer className="footer-container">
                <div className="row footer-container d-lg-none m-0 p-0">
                    <div className="col footer-logo align-self-center">
                        <Link to='/'>
                            <img alt="logo" src={logo} />
                        </Link>
                    </div>
                    <div className="col d-flex justify-content-center p-0 m-0">
                        <ul className="row">
                            <li className="col">
                                <a
                                    href="https://www.facebook.com/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <svg className="social-icon" fill="none" viewBox="0 0 44 44"
                                         xmlns="http://www.w3.org/2000/svg">
                                        {/* SVG path */}
                                    </svg>
                                </a>
                            </li>
                            <li className="col">
                                <a
                                    className="footer-social"
                                    href="https://www.instagram.com/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <svg className="social-icon" fill="none" viewBox="0 0 43 43"
                                         xmlns="http://www.w3.org/2000/svg">
                                        {/* SVG path */}
                                    </svg>
                                </a>
                            </li>
                            <li className="col">
                                <a
                                    className="footer-social"
                                    href="https://x.com/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <svg className="social-icon" fill="none" viewBox="0 0 38 38"
                                         xmlns="http://www.w3.org/2000/svg">
                                        {/* SVG path */}
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row mt-5 mb-5">
                    <div className="col footer-logo align-self-center d-none d-lg-block">
                        <Link to='/'>
                            <img alt="logo" src={logo} />
                        </Link>
                    </div>
                    <div className="col d-flex flex-column align-items-center justify-content-center">
                        <ul>
                            <li className="row mb-2 mb-lg-4">
                                <Link className="footer-link" to='/'>Home</Link>
                            </li>
                            <li className="row mb-2 mb-lg-4">
                                <Link className="footer-link" to='/courses'>Courses</Link>
                            </li>
                            <li className="row mb-2 mb-lg-4">
                                <Link className="footer-link" to='/faq'>FAQs</Link>
                            </li>
                            <li className="row">
                                <Link className="footer-link" to='/about-us'>About Us</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col d-flex flex-column align-items-center justify-content-center">
                        <ul>
                            <li className="row mb-2 mb-lg-4">
                                <button className="footer-link btn btn-link p-0" onClick={() => alert('Help clicked')}>Help</button>
                            </li>
                            <li className="row mb-2 mb-lg-4">
                                <button className="footer-link btn btn-link p-0" onClick={() => alert('Terms clicked')}>Terms of Use</button>
                            </li>
                            <li className="row mb-2 mb-lg-4">
                                <button className="footer-link btn btn-link p-0" onClick={() => alert('Privacy clicked')}>Privacy Policy</button>
                            </li>
                            <li className="row">
                                <button className="footer-link btn btn-link p-0" onClick={() => alert('Cookies clicked')}>Cookie Settings</button>
                            </li>
                        </ul>
                    </div>

                    <div className="col d-flex flex-column align-items-center justify-content-center mb-0">
                        <ul>
                            <li className="row mb-2" id="footer-contact-us-title"><p>CONTACT US</p></li>
                            <li className="row mb-1" id="footer-address">
                                <p className="mb-0">
                                    1234 Elm Street, Suite 567<br />
                                    Cityville, CA 90210<br />
                                    USA
                                </p>
                            </li>
                            <li className="row" id="footer-phone-number">
                                <p className="mb-0">+1 (555) 123-4567</p>
                            </li>
                        </ul>
                    </div>

                    <div className="col d-flex flex-column align-items-center justify-content-center d-none d-lg-block">
                        <ul>
                            <li className="row">
                                <a
                                    href="https://www.facebook.com/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <svg className="social-icon" fill="none" viewBox="0 0 44 44"
                                         xmlns="http://www.w3.org/2000/svg">
                                        {/* SVG path */}
                                    </svg>
                                </a>
                            </li>
                            <li className="row">
                                <a
                                    className="footer-social"
                                    href="https://www.instagram.com/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <svg className="social-icon" fill="none" viewBox="0 0 43 43"
                                         xmlns="http://www.w3.org/2000/svg">
                                        {/* SVG path */}
                                    </svg>
                                </a>
                            </li>
                            <li className="row">
                                <a
                                    className="footer-social"
                                    href="https://x.com/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <svg className="social-icon mb-0" fill="none" viewBox="0 0 38 38"
                                         xmlns="http://www.w3.org/2000/svg">
                                        {/* SVG path */}
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
};

export default Footer;
