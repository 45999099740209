import './tabs.style.scss';

const TabsComponent = ({tabs, activeTab, onTabClick}) =>{
    return (
        <div className="tabs-container">
            {tabs.map((tab) => (
                <div
                    key={tab.key}
                    className={`tab-item ${activeTab === tab.key ? "active" : ""}`}
                    onClick={() => onTabClick(tab.key)}
                >
                    {tab.label}
                </div>
            ))}
        </div>
    )
}
export default TabsComponent;