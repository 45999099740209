import './forgot-password.style.scss';
import React, {Fragment} from "react";
import Footer from "../../../components/layout/footer/footer.component";
import Navigation from "../../../components/layout/navigation/navigation.component";

const ForgotPassword = () =>{
    return (
        <Fragment>
            <Navigation/>
            <div className="forgot-password-container">
                <div className="forgot-password-header">
                    <h2 className="forgot-password-title">Forgot Password?</h2>
                    <p className="forgot-password-subtitle">We will send you a link to reset your password</p>
                </div>

                <div className="forgot-password-input-wrapper">
                    <label className="forgot-password-label">Email</label>
                    <input name="email" className="forgot-password-email-input" type="email"
                           placeholder="john.doe@gmail.com"/>
                </div>

                <div className="forgot-password-button-wrapper">
                    <button className="forgot-password-submit-button">Continue</button>
                </div>
            </div>
            <Footer/>
        </Fragment>
    );
}
export default ForgotPassword;