import './student-home.style.scss';
import React, {Fragment} from "react";
import Navigation from "../../../components/layout/navigation/navigation.component";
import Footer from "../../../components/layout/footer/footer.component";
import CalendarScheduleComponent from "../../../components/common/calendar-schedule/calendar-schedule.component";
import CourseCard from "../../../components/common/course-card/course-card.component";
import {useNavigate} from "react-router-dom";
import Pagination from "../../../components/common/pagination/pagination.component";
import order from '../../../assets/order.svg';
const courses = [
    {
        title: "Build Responsive Real-World Websites with HTML and CSS",
        author: "Jonas Schmedtmann",
        rating: 5,
        reviews: "25,000",
        price: "49.99",
        image: "https://img-c.udemycdn.com/course/240x135/437398_46c3_10.jpg",
        link: "https://www.udemy.com/course-dashboard-redirect/?course_id=437398"
    },
    {
        title: "100 Days of Code: The Complete Python Pro Bootcamp",
        author: "Dr. Angela Yu",
        rating: 5,
        reviews: "120,000",
        price: "59.99",
        image: "https://img-c.udemycdn.com/course/240x135/2776760_f176_10.jpg",
        link: "https://www.udemy.com/course-dashboard-redirect/?course_id=2776760"
    },
    {
        title: "Java 17 Masterclass: Start Coding in 2024",
        author: "Tim Buchalka",
        rating: 4.7,
        reviews: "150,000",
        price: "39.99",
        image: "https://img-c.udemycdn.com/course/240x135/533682_c10c_4.jpg",
        link: "https://www.udemy.com/course/java-the-complete-java-developer-course/"
    },
    {
        title: "Go - The Complete Guide",
        author: "Maximilian Schwarzmüller",
        rating: 4.6,
        reviews: "50,000",
        price: "44.99",
        image: "https://img-c.udemycdn.com/course/240x135/5652172_fc8b.jpg",
        link: "https://www.udemy.com/course/go-the-complete-guide/"
    },

];
const StudentHome = () => {
    const navigate = useNavigate();
    const redirectToCourses = ()=>{
        navigate('/courses')
    }
    return(
        <Fragment>
            <Navigation></Navigation>
            <CalendarScheduleComponent/>
            <section className="courses-section">
                <h1 className="courses-title">Enrolled Courses</h1>
                <div className="courses-list">
                    {courses.map((course, index) => (
                        <CourseCard key={index} course={course} btn={'View'}/>
                    ))}
                </div>
                <button className="see-more-button" id="seeMoreBtn" onClick={redirectToCourses}>
                    <span>See More</span>
                    <span className="see-more-icon">
                        <svg width="29" height="18" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.9375 1.4375L30.125 10.5M30.125 10.5L17.9375 19.5625M30.125 10.5H0.875"
                                stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                </button>
            </section>
            <div className="exam-table-container">
                <h2>Exams</h2>
                <table>
                    <thead>
                    <tr>
                        <th><span>Course Name</span> <img src={order} alt=""/></th>
                        <th><span>Start Date</span> <img src={order} alt=""/></th>
                        <th><span>Status</span> <img src={order} alt=""/></th>
                        <th><span>Average Grade</span> <img src={order} alt=""/></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>JavaScript for Beginners</td>
                        <td>2.09.2023</td>
                        <td className="course-active">Active</td>
                        <td>95/100</td>
                    </tr>
                    <tr className="alt-row">
                        <td>Intro to Digital Marketing</td>
                        <td>4.10.2023</td>
                        <td className="course-active">Active</td>
                        <td>85/100</td>
                    </tr>
                    <tr>
                        <td>Calculus Made Easy</td>
                        <td>24.08.2023</td>
                        <td className="course-active">Active</td>
                        <td>95/100</td>
                    </tr>
                    <tr className="alt-row course-inactive">
                        <td>Creative Writing</td>
                        <td>14.11.2023</td>
                        <td>Inactive</td>
                        <td>85/100</td>
                    </tr>
                    <tr className="course-inactive">
                        <td>Startup Basics</td>
                        <td>24.01.2024</td>
                        <td>Inactive</td>
                        <td>85/100</td>
                    </tr>
                    </tbody>
                </table>
                <Pagination></Pagination>
            </div>
            <Footer></Footer>
        </Fragment>
    )
}
export default StudentHome;