import './student-courses.style.scss';
import {Fragment, useEffect, useState} from "react";
import Navigation from "../../../components/layout/navigation/navigation.component";
import Footer from "../../../components/layout/footer/footer.component";
import TabsComponent from "../../../components/common/tabs/tabs.component";
import SearchComponent from "../../../components/common/search/search.component";
import CourseCard from "../../../components/common/course-card/course-card.component";
import Pagination from "../../../components/common/pagination/pagination.component";
import FilteringComponent from "../../../components/common/filtering/filtering.component";
const courses = [
    {
        title: "Build Responsive Real-World Websites with HTML and CSS",
        author: "Jonas Schmedtmann",
        rating: 5,
        reviews: "25,000",
        price: "49.99",
        image: "https://img-c.udemycdn.com/course/240x135/437398_46c3_10.jpg",
        link: "https://www.udemy.com/course-dashboard-redirect/?course_id=437398"
    },
    {
        title: "100 Days of Code: The Complete Python Pro Bootcamp",
        author: "Dr. Angela Yu",
        rating: 5,
        reviews: "120,000",
        price: "59.99",
        image: "https://img-c.udemycdn.com/course/240x135/2776760_f176_10.jpg",
        link: "https://www.udemy.com/course-dashboard-redirect/?course_id=2776760"
    },
    {
        title: "Java 17 Masterclass: Start Coding in 2024",
        author: "Tim Buchalka",
        rating: 4.7,
        reviews: "150,000",
        price: "39.99",
        image: "https://img-c.udemycdn.com/course/240x135/533682_c10c_4.jpg",
        link: "https://www.udemy.com/course/java-the-complete-java-developer-course/"
    },
    {
        title: "Go - The Complete Guide",
        author: "Maximilian Schwarzmüller",
        rating: 4.6,
        reviews: "50,000",
        price: "44.99",
        image: "https://img-c.udemycdn.com/course/240x135/5652172_fc8b.jpg",
        link: "https://www.udemy.com/course/go-the-complete-guide/"
    },
    {
        title: "The Complete Spring Boot Development Bootcamp",
        author: "Learn The Part Inc.",
        rating: 4.9,
        reviews: "75,000",
        price: "54.99",
        image: "https://img-c.udemycdn.com/course/240x135/4695284_83ea_6.jpg",
        link: "https://www.udemy.com/course-dashboard-redirect/?course_id=4695284"
    },
    {
        title: "Complete C# Unity Game Developer 2D",
        author: "GameDev.tv Team",
        rating: 4.8,
        reviews: "80,000",
        price: "45.99",
        image: "https://img-c.udemycdn.com/course/240x135/258316_55e9_12.jpg",
        link: "https://www.udemy.com/course/unitycourse/?couponCode=KEEPLEARNING"
    },
    {
        title: "Figma UI UX Design Essentials",
        author: "Daniel Walter Scott",
        rating: 4.7,
        reviews: "30,000",
        price: "35.99",
        image: "https://img-c.udemycdn.com/course/240x135/4359576_b9e1_2.jpg",
        link: "https://www.udemy.com/course/figma-ux-ui-design-user-experience-tutorial-course/"
    },
    {
        title: "Master the Fundamentals of Math",
        author: "Krista King",
        rating: 4.9,
        reviews: "60,000",
        price: "29.99",
        image: "https://img-c.udemycdn.com/course/240x135/221578_40f0_7.jpg",
        link: "https://www.udemy.com/course/fundamentals-of-math/"
    },
    {
        title: "Fundamentals of Database Engineering",
        author: "Hussein Nasser",
        rating: 4.8,
        reviews: "55,000",
        price: "42.99",
        image: "https://img-c.udemycdn.com/course/240x135/2722880_af31_7.jpg",
        link: "https://www.udemy.com/course/fundamentals-of-database-engineering/"
    },
    {
        title: "Instagram Growth Tips",
        author: "By John Payne",
        rating: 4,
        reviews: "1200",
        price: "42.99",
        image: "https://s3-alpha-sig.figma.com/img/9f35/570f/ea664173f06baa1a6b2b64ce0335003e?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=nUhiNpa5okVD8t0MqIlcAcpToBY3DA7uTV7ewXuWvVO9o0g5MOx-ROC1Gldu8sCusMrAx9rJUCit4fY-bYSMpin8mivGAzDPKWBTHDxtMshKiu31g9nOpbD06RsXoHfLVY1IsTbYfRKJTlvHrmm40ud3qlDpfsM1n2-PlsvlvX3aHnwLIdCWuX88kNf7Vg3bBg3aB2A5ERw~bF6W5By5~4aJDQ1HGSKJ0a5oG2YzvK0blRX47m4dPgBlHekIKz2fBEuvDMBvSm0Z3jYX5mpO3q4prM5kG2nGdn8pmwaEEmw8yOgyhb~kb0~stubn4Vl9fws40xn~Ftcwl-63TxSdcw__",
        link: "https://www.udemy.com/course/fundamentals-of-database-engineering/"
    },
    {
        title: "Prototyping with Figma",
        author: "By Sara Paul",
        rating: 2,
        reviews: "5,000",
        price: "35.99",
        image: 'https://s3-alpha-sig.figma.com/img/c8e0/b393/76e568b86c0be0b91125ba9eb900a152?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=VDtx5kGHxJzEreDgxytXooAdRETiBOt1iA7LYMi66BgbasYa-d5VE8X5z--TkmOnWKWX7fb6BCSXNDD~hJGondH-frqFmoOZCbYbfcHRQd6h4is1v2~x~mTvlol9Ny4skujaRl2K873iBnq~UrIK8jibdEzP5Ln3DSKgU-PJE0jwi70yc5-fOie18XpKYL035w2TPMUf1vxYU12TxI3yCuYU80h-9d44Ga1N6Ulan~jT3fsAMHR6kl8ACmZvMOVGD4JxYZYG5pcNPdUMVXrRGSiCu1G6rwjX0GBT4fnlKoExUgd2vRjecNe4Rcc0Gqa8t5TRgiLPKd-dKKGQK3PeMQ__',
        link: "https://www.udemy.com/course/fundamentals-of-database-engineering/"
    },
    {
        title: "Startup Basics",
        author: "By Affan Khan",
        rating: 3.2,
        reviews: "900",
        price: "12.99",
        image: 'https://s3-alpha-sig.figma.com/img/a801/d2c6/0ce2b83df090ec064ca3bf9f2db24674?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=Q22avviyw7CvzHr~Bc0748OzkXSidmrpFKxB3gLP988oNlMjEDAozP7jL3TUaAjBPBMuVdNTwA-Pxhce9RpO63mfvHOapIUhkoUWfnItz9-PediP3ESRRqMKUGWTRi3XlTnUhsYs28D9zEXzlay9NuhGa3NfOgTjSNKBtwfPBnOQU4Q7nz7AkUmSnF5MH~IgNQnpFb136-1H-3UL8vOMEJ3MUlKe0h4ujka9uR1aaWEnPpyNBpjZX-phaBjQndGxDID4xWWD7gYjbFh2HZ3norip~pCnLUlKva85KQDvS13XQF8gznnESSTRsIcvGR-6hGFgSrrjIhbdlreffJnXFg__',
        link: "https://www.udemy.com/course/fundamentals-of-database-engineering/"
    }
];
const StudentCourses = () =>{
    const [activeTab, setActiveTab] = useState("enrolled");
    const [shuffledCourses, setShuffledCourses] = useState([...courses]);

    const tabs = [
        { key: "all", label: "All Courses" },
        { key: "enrolled", label: "Enrolled Courses" },
    ];

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    useEffect(() => {
        if(activeTab === 'all'){
            const shuffled = [...courses].sort(() => Math.random() - 0.5);
            setShuffledCourses(shuffled);
        }
        else {
            setShuffledCourses(courses);
        }
    }, [activeTab]);

    return(
        <Fragment>
            <Navigation></Navigation>
            <div className="student-courses-container">
                <div className="student-courses-tabs">
                    <TabsComponent
                        className="tabs-component"
                        tabs={tabs}
                        activeTab={activeTab}
                        onTabClick={handleTabClick}
                    />
                </div>
                {activeTab === 'enrolled' &&
                    <Fragment>
                        <SearchComponent/>
                        <div className="student-enrolled-courses-list">
                            {shuffledCourses.map((course, index) => (
                                <CourseCard key={index} course={course} btn={activeTab === 'enrolled' ? "View" : 'Enroll Now'} className="course-card"/>
                            ))}
                        </div>
                        <div className='student-courses-pagination'>
                            <Pagination></Pagination>
                        </div>
                    </Fragment>
                }
                {activeTab === 'all' &&
                    <Fragment>
                        <SearchComponent/>
                        <div className='student-courses-wrap'>
                            <FilteringComponent></FilteringComponent>
                            <div className="student-enrolled-courses-list">
                                {shuffledCourses.map((course, index) => (
                                    <CourseCard key={index} course={course}
                                                btn={activeTab === 'enrolled' ? "View" : 'Enroll Now'}
                                                className="course-card"/>
                                ))}
                            </div>
                            <div className='student-courses-pagination'>
                                <Pagination></Pagination>
                            </div>
                        </div>

                    </Fragment>
                }

            </div>
            <Footer></Footer>
        </Fragment>
    )
}
export default StudentCourses;