import { Fragment, useState } from 'react';
import './contact-us.style.scss';
import Navigation from '../../../components/layout/navigation/navigation.component';
import Footer from '../../../components/layout/footer/footer.component';

const ContactUs = () =>{

    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const handleChange = (e) => {
        setIsFormValid(formData.name && formData.email && formData.message);
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };


    // const validateForm = () => {
    //     let newErrors = {};
    //     if (!formData.name.trim()) newErrors.name = 'Please enter your name';
    //     if (!formData.email.trim()) {
    //         newErrors.email = 'Please enter your email';
    //     } else if (!isValidEmail(formData.email)) {
    //         newErrors.email = 'Please enter a valid email address';
    //     }
    //     if (!formData.message.trim()) newErrors.message = 'Please enter your message';
    //     setErrors(newErrors);
    //     return Object.keys(newErrors).length === 0;
    // };
    const validateForm = () => {
        let tempErrors = {};
        let isValid = true;

        if (!formData.name.trim()) {
            tempErrors.name = "Please enter your name";
            isValid = false;
        }

        if (!formData.email.trim()) {
            tempErrors.email = "Please enter your email";
            isValid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            tempErrors.email = "Please enter a valid email address";
            isValid = false;
        }

        if (!formData.message.trim()) {
            tempErrors.message = "Please enter your message";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setShowModal(true);
            setFormData({ name: "", email: "", message: "" });
            setIsFormValid(false);
        }
    };

    return(
        <Fragment>
            <Navigation></Navigation>
            <section className="container-fluid my-5">
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10 contact-body">
                        <div className="col-6 px-4">
                            <div className="contact-info">
                                <h4>Contact Info</h4>
                                <p>Need help? Contact us by email, phone, or visit our offices—we're here for you!</p>
                            </div>
                            <ul className="list-unstyled">
                                <li className="contact-item">
                                    <svg fill="none" height="80" viewBox="0 0 80 80" width="80"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="40" cy="40" fill="#2B3A67" r="40"/>
                                        <path
                                                d="M47.2266 35.5586C47.2266 37.268 46.5475 38.9074 45.3388 40.1161C44.13 41.3248 42.4907 42.0039 40.7812 42.0039C39.0718 42.0039 37.4325 41.3248 36.2237 40.1161C35.015 38.9074 34.3359 37.268 34.3359 35.5586C34.3359 33.8492 35.015 32.2098 36.2237 31.0011C37.4325 29.7923 39.0718 29.1133 40.7812 29.1133C42.4907 29.1133 44.13 29.7923 45.3388 31.0011C46.5475 32.2098 47.2266 33.8492 47.2266 35.5586Z"
                                                stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                        <path
                                                d="M56.8945 35.5586C56.8945 50.9027 40.7812 59.7285 40.7812 59.7285C40.7812 59.7285 24.668 50.9027 24.668 35.5586C24.668 31.2851 26.3656 27.1866 29.3874 24.1648C32.4093 21.143 36.5077 19.4453 40.7812 19.4453C45.0548 19.4453 49.1532 21.143 52.1751 24.1648C55.1969 27.1866 56.8945 31.2851 56.8945 35.5586Z"
                                                stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                    </svg>
                                    <p style={{marginTop: '20px', marginBottom: '0px'}}>1234 Elm Street, Suite 567<br/>Cityville,
                                        CA 90210<br/>USA</p>
                                </li>
                                <li className="contact-item">
                                    <svg fill="none" height="80" viewBox="0 0 80 80" width="80"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="40" cy="40" fill="#2B3A67" r="40"/>
                                        <path
                                                d="M21 29.5385C21 45.2568 34.432 58 51 58H55.5C56.6935 58 57.8381 57.5502 58.682 56.7496C59.5259 55.9489 60 54.863 60 53.7308V51.1275C60 50.1484 59.298 49.2946 58.296 49.0574L49.45 46.9588C48.57 46.7501 47.646 47.0632 47.104 47.7501L45.164 50.2034C44.6 50.9169 43.626 51.2318 42.744 50.9245C39.4698 49.7824 36.4963 47.9789 34.0296 45.6386C31.5628 43.2984 29.6617 40.4774 28.458 37.3711C28.134 36.5343 28.466 35.6103 29.218 35.0752L31.804 33.2347C32.53 32.7205 32.858 31.8419 32.638 31.009L30.426 22.6166C30.3043 22.155 30.0234 21.7451 29.6281 21.4522C29.2328 21.1593 28.7456 21.0002 28.244 21H25.5C24.3065 21 23.1619 21.4498 22.318 22.2504C21.4741 23.0511 21 24.137 21 25.2692V29.5385Z"
                                                stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="1.5"/>
                                    </svg>
                                    <p style={{marginTop: '20px'}}>+1 (555) 123-4567</p>
                                </li>
                                <li className="contact-item">
                                    <svg fill="none" height="80" viewBox="0 0 80 80" width="80"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="40" cy="40" fill="#2B3A67" r="40"/>
                                        <path
                                                d="M59 30.05V48.95C59 50.0241 58.5259 51.0543 57.682 51.8138C56.8381 52.5733 55.6935 53 54.5 53H24.5C23.3065 53 22.1619 52.5733 21.318 51.8138C20.4741 51.0543 20 50.0241 20 48.95V30.05M59 30.05C59 28.9759 58.5259 27.9457 57.682 27.1862C56.8381 26.4267 55.6935 26 54.5 26H24.5C23.3065 26 22.1619 26.4267 21.318 27.1862C20.4741 27.9457 20 28.9759 20 30.05M59 30.05V30.4874C59.0001 31.1789 58.8035 31.8588 58.4289 32.4625C58.0543 33.0662 57.5142 33.5736 56.86 33.9362L41.86 42.2432C41.1504 42.6366 40.3334 42.8448 39.5 42.8448C38.6666 42.8448 37.8496 42.6366 37.14 42.2432L22.14 33.938C21.4858 33.5754 20.9457 33.068 20.5711 32.4643C20.1965 31.8606 19.9999 31.1807 20 30.4892V30.05"
                                                stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="1.5"/>
                                    </svg>
                                    <p style={{marginTop: '20px'}}>teachlink1@gmail.com</p>
                                </li>
                            </ul>
                        </div>
                        <div className="custom-class col-6">
                            <div className="card contact-us-card p-4">
                                <h4>Send us a message</h4>
                                <form id="contactForm" onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <input className={`form-control custom-name ${errors.name ? 'is-invalid' : ''}`}
                                               id="name" placeholder="Enter Your Name" type="text" value={formData.name}
                                               onChange={handleChange}/>
                                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            className={`form-control custom-mail ${errors.email ? 'is-invalid' : ''}`}
                                            id="email" placeholder="Enter Your Email Address" type="email"
                                            value={formData.email} onChange={handleChange}/>
                                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <textarea
                                            className={`form-control custom-textarea ${errors.message ? 'is-invalid' : ''}`}
                                            id="message" placeholder="Write your message.." rows="4"
                                            value={formData.message} onChange={handleChange}></textarea>
                                        {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                                    </div>
                                    <button className="btn btn-primary custom-btn" type="submit" disabled={!isFormValid}>Send</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-1"></div>
                </div>
            </section>
            {showModal && (
                // <div className="modal fade show d-block" tabIndex="-1" role="dialog">
                //     <div className="modal-dialog modal-dialog-centered" role="document">
                //         <div className="modal-content">
                //             <div className="modal-header border-0">
                //                 <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                //             </div>
                //             <div className="modal-body text-center pb-5">
                //                 <h2>Thank You!</h2>
                //                 <p>Your message has been sent successfully.</p>
                //                 <button className="btn btn-primary" onClick={() => setShowModal(false)}>Close</button>
                //             </div>
                //         </div>
                //     </div>
                // </div>
                <div className="modal fade show d-block" id="thankYouModal" role='dialog' tabIndex="-1" aria-labelledby="thankYouModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setShowModal(false)}
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center pb-5">
                                <svg width="160" height="160" viewBox="0 0 160 160" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M145 45V115C145 118.978 143.42 122.794 140.607 125.607C137.794 128.42 133.978 130 130 130H30C26.0218 130 22.2064 128.42 19.3934 125.607C16.5804 122.794 15 118.978 15 115V45M145 45C145 41.0218 143.42 37.2064 140.607 34.3934C137.794 31.5804 133.978 30 130 30H30C26.0218 30 22.2064 31.5804 19.3934 34.3934C16.5804 37.2064 15 41.0218 15 45M145 45V46.62C145 49.181 144.345 51.6994 143.096 53.9353C141.848 56.1712 140.047 58.0502 137.867 59.3933L87.8667 90.16C85.5014 91.6169 82.778 92.3883 80 92.3883C77.222 92.3883 74.4986 91.6169 72.1333 90.16L22.1333 59.4C19.9528 58.0569 18.1525 56.1779 16.9038 53.942C15.6551 51.706 14.9997 49.1876 15 46.6267V45"
                                        stroke="#2B3A67" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    <circle cx="136.5" cy="124.5" r="23.5" fill="#2B3A67"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M148.017 114.889C148.15 114.989 148.262 115.114 148.346 115.256C148.43 115.399 148.485 115.556 148.508 115.72C148.53 115.884 148.52 116.05 148.477 116.21C148.435 116.369 148.36 116.519 148.259 116.65L134.711 134.231C134.601 134.374 134.461 134.491 134.302 134.576C134.142 134.66 133.966 134.71 133.785 134.722C133.604 134.734 133.423 134.708 133.253 134.645C133.084 134.582 132.93 134.483 132.802 134.357L125.181 126.822C124.956 126.584 124.834 126.269 124.84 125.944C124.846 125.619 124.979 125.308 125.212 125.078C125.445 124.848 125.759 124.716 126.088 124.71C126.417 124.705 126.735 124.825 126.976 125.047L133.571 131.566L146.239 115.128C146.443 114.864 146.744 114.69 147.078 114.645C147.411 114.6 147.749 114.688 148.017 114.889Z"
                                          fill="#F5F5F5"/>
                                </svg>
                                <h2 className="modal-title mt-4" id="thankYouModalLabel">Thanks for your message!</h2>
                                <p className="mt-3">We will be in touch soon.</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Footer></Footer>
        </Fragment>
    );
}
export default ContactUs;