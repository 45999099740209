import React from 'react';
import { Routes, Route } from 'react-router-dom'
import AdminHome from "../pages/admin/admin-home/admin-home.component";
import AdminCourses from "../pages/admin/admin-courses/admin-courses.component";
import AdminPayments from "../pages/admin/admin-payments/admin-payments.component";
import AdminCourseDetails from "../pages/admin/admin-course-details/admin-course-details.component";

const AdminRoutes = () => {
    return(
        <Routes>
            <Route path="/" element={<AdminHome/>}></Route>
            <Route path="/courses" element={<AdminCourses/>}></Route>
            <Route path="/course-details" element={<AdminCourseDetails/>}></Route>
            <Route path="/payments" element={<AdminPayments/>}></Route>
        </Routes>
    )
};

export default AdminRoutes;