import { useNavigate } from 'react-router-dom';
import Footer from '../../../components/layout/footer/footer.component';
import Navigation from '../../../components/layout/navigation/navigation.component';
import './landing.style.scss';
import React, {Fragment} from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import CourseCard from "../../../components/common/course-card/course-card.component";

const courses = [
    {
        title: "Build Responsive Real-World Websites with HTML and CSS",
        author: "Jonas Schmedtmann",
        rating: 5,
        reviews: "25,000",
        price: "49.99",
        image: "https://img-c.udemycdn.com/course/240x135/437398_46c3_10.jpg",
        link: "https://www.udemy.com/course-dashboard-redirect/?course_id=437398"
    },
    {
        title: "100 Days of Code: The Complete Python Pro Bootcamp",
        author: "Dr. Angela Yu",
        rating: 5,
        reviews: "120,000",
        price: "59.99",
        image: "https://img-c.udemycdn.com/course/240x135/2776760_f176_10.jpg",
        link: "https://www.udemy.com/course-dashboard-redirect/?course_id=2776760"
    },
    {
        title: "Java 17 Masterclass: Start Coding in 2024",
        author: "Tim Buchalka",
        rating: 4.7,
        reviews: "150,000",
        price: "39.99",
        image: "https://img-c.udemycdn.com/course/240x135/533682_c10c_4.jpg",
        link: "https://www.udemy.com/course/java-the-complete-java-developer-course/"
    },
    {
        title: "Go - The Complete Guide",
        author: "Maximilian Schwarzmüller",
        rating: 4.6,
        reviews: "50,000",
        price: "44.99",
        image: "https://img-c.udemycdn.com/course/240x135/5652172_fc8b.jpg",
        link: "https://www.udemy.com/course/go-the-complete-guide/"
    },

];
const Landing = ()=>{
    const navigate = useNavigate();
    const redirectToCourses = ()=>{
        navigate('/courses')
    }
    const redirectToAuht = ()=>{
        navigate('/authentication')
    }
    return (
        <Fragment>
            <Navigation></Navigation>
            <Fragment>
                <section className="hero-section d-flex flex-column align-items-center justify-content-center"
                         style={{backgroundColor: "#2B3A67"}}>
                    <h1 className="hero-title text-white">Learn & Teach Anything, Anytime</h1>
                    <p className="hero-description text-white">
                        <span>Join TeachLink to explore limitless learning</span>
                        <span>opportunities and share your expertise across {" "}</span>
                        <span>diverse subjects.</span>
                    </p>
                    <div className="hero-buttons d-flex gap-3">
                        <button className="get-started-btn d-flex align-items-center gap-2" onClick={redirectToAuht}>
                            <span>Get Started</span>
                            <span className="get-started-icon">
                    <svg width="29" height="18" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9375 1.4375L30.125 10.5M30.125 10.5L17.9375 19.5625M30.125 10.5H0.875"
                              stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
                        </button>
                        <button className="browse-courses-btn" onClick={redirectToCourses}>Browse Courses</button>
                    </div>
                </section>
                <section className="how-it-works" id="how-it-works">
                    <h1 className="how-it-works-title">HOW IT WORKS ?</h1>
                    <div className="how-it-works-container d-flex justify-content-center px-4 py-5">
                        <div className="step d-flex flex-column align-items-center justify-content-center px-5 py-5">
                            <div className="step-icon d-flex align-items-center justify-content-center rounded-circle"
                                 style={{width: "80px", height:"80px"}}>
                                <svg width="33px" height="43px" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.9999 15C8.82977 15 6.01065 16.5306 4.21585 18.906C3.82956 19.4172 3.63641 19.6728 3.64273 20.0183C3.64761 20.2852 3.81521 20.6219 4.02522 20.7867C4.29704 21 4.67372 21 5.42708 21H18.5726C19.326 21 19.7027 21 19.9745 20.7867C20.1845 20.6219 20.3521 20.2852 20.357 20.0183C20.3633 19.6728 20.1701 19.4172 19.7839 18.906C17.9891 16.5306 15.1699 15 11.9999 15Z"
                                        stroke="#FFFFFF" stroke-width="0.4800000000000001" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                    <path
                                        d="M11.9999 12C14.4851 12 16.4999 9.98528 16.4999 7.5C16.4999 5.01472 14.4851 3 11.9999 3C9.51457 3 7.49985 5.01472 7.49985 7.5C7.49985 9.98528 9.51457 12 11.9999 12Z"
                                        stroke="#FFFFFF" stroke-width="0.4800000000000001" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                            </div>
                            <div className="step-description text-center" style={{color: "#222222"}}>SIGN UP AND
                                PERSONALIZE PROFILE
                            </div>
                        </div>
                        <div className="step d-flex flex-column align-items-center justify-content-center px-5 py-5">
                            <div className="step-icon d-flex align-items-center justify-content-center rounded-circle"
                                 style={{width: "80px", height:"80px"}}>
                                <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
                                        stroke="#FFFFFF" stroke-width="0.4800000000000001" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                            </div>
                            <div className="step-description text-center" style={{color: "#222222"}}>EXPLORE AND ENROLL IN
                                COURSES
                            </div>
                        </div>
                        <div className="step d-flex flex-column align-items-center justify-content-center px-5 py-5">
                            <div className="step-icon d-flex align-items-center justify-content-center rounded-circle"
                                 style={{width: "80px", height:"80px", justifySelf: "baseline"}}>
                                <svg width="36" height="40" style={{paddingLeft: "8px"}} viewBox="0 0 36 40" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 3.86467C1 1.68829 3.26086 0.310251 5.10999 1.35776L33.5619 17.4951C33.9973 17.7418 34.3605 18.1046 34.6135 18.5456C34.8665 18.9866 35 19.4896 35 20.002C35 20.5144 34.8665 21.0173 34.6135 21.4583C34.3605 21.8993 33.9973 22.2622 33.5619 22.5089L5.10999 38.6462C4.68761 38.8856 4.21201 39.0075 3.73007 38.9996C3.24813 38.9918 2.77648 38.8546 2.36162 38.6016C1.94675 38.3485 1.603 37.9884 1.36423 37.5566C1.12546 37.1248 0.999927 36.6363 1 36.1392V3.86467Z"
                                        stroke="#F5F5F5" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div className="step-description text-center" style={{color: "#222222"}}>START LEARNING OR
                                TEACHING
                            </div>
                        </div>
                    </div>
                </section>
                <section class="courses-section">
                    <h1 class="courses-title">TOP COURSES</h1>
                    <div class="courses-list">
                        {courses.map((course, index) => (
                            <CourseCard key={index} course={course} />
                        ))}
                    </div>
                    <button class="see-more-button" id="seeMoreBtn" onClick={redirectToCourses}>
                      <span>See More</span>
                      <span class="see-more-icon">
                        <svg width="29" height="18" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.9375 1.4375L30.125 10.5M30.125 10.5L17.9375 19.5625M30.125 10.5H0.875" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                    </button>
              </section>
                <section className="reviews-section">
                    <h1 className="reviews-title">REVIEWS</h1>
                    <div className="reviews-container d-flex flex-column">
                        <div className="review-wrap ">
                            <div className="review-item">
                                <div className="review-content">
                                    <div className="review-rating">
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                    </div>
                                    <div className="review-text">
                                        “So far, I’ve been having a great experience learning on this platform”
                                    </div>
                                    <div className="review-author">Melda S.</div>
                                </div>
                            </div>
                            <div className="review-item">
                                <div className="review-content">
                                    <div className="review-rating">
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                    </div>
                                    <div className="review-text">
                                        “I’ve tried a bunch of online teaching platforms, but TeachLink is my favorite!”
                                    </div>
                                    <div className="review-author">Thomas T.</div>
                                </div>
                            </div>
                            <div className="review-item">
                                <div className="review-content">
                                    <div className="review-rating">
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                    </div>
                                    <div className="review-text">
                                        “I love teaching on this platform; it’s a great way to connect with students!”
                                    </div>
                                    <div className="review-author">Rabiya N.</div>
                                </div>
                            </div>
                            <div className="review-item">
                                <div className="review-content ">
                                    <div className="review-rating">
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                    </div>
                                    <div className="review-text">
                                        “I can’t believe I actually understand calculus now! Thank you TeachLink!"
                                    </div>
                                    <div className="review-author">Louis K.</div>
                                </div>
                            </div>
                            <div className="review-item">
                                <div className="review-content">
                                    <div className="review-rating">
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="30" height="29" viewBox="0 0 30 29" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.3145 1.53777C14.3797 1.37872 14.4907 1.24266 14.6335 1.1469C14.7762 1.05113 14.9442 1 15.1161 1C15.288 1 15.456 1.05113 15.5988 1.1469C15.7416 1.24266 15.8526 1.37872 15.9178 1.53777L19.1938 9.41723C19.2552 9.56467 19.356 9.69233 19.4852 9.78617C19.6144 9.88001 19.767 9.93639 19.9261 9.9491L28.4331 10.6305C29.2023 10.6922 29.5138 11.6526 28.9279 12.1537L22.4468 17.7068C22.3257 17.8104 22.2354 17.9453 22.1859 18.0967C22.1365 18.2482 22.1296 18.4104 22.1662 18.5655L24.1472 26.8674C24.187 27.0339 24.1766 27.2085 24.1172 27.3692C24.0579 27.5298 23.9522 27.6692 23.8137 27.7699C23.6751 27.8705 23.5098 27.9278 23.3387 27.9345C23.1676 27.9413 22.9983 27.8972 22.8522 27.8078L15.5678 23.3601C15.4318 23.277 15.2755 23.233 15.1161 23.233C14.9567 23.233 14.8004 23.277 14.6644 23.3601L7.38005 27.8093C7.23399 27.8987 7.06471 27.9428 6.89359 27.9361C6.72247 27.9293 6.55719 27.872 6.41862 27.7714C6.28005 27.6708 6.17441 27.5313 6.11504 27.3707C6.05567 27.2101 6.04524 27.0355 6.08506 26.8689L8.0661 18.5655C8.10284 18.4104 8.09608 18.2482 8.04658 18.0967C7.99708 17.9452 7.90674 17.8102 7.78551 17.7068L1.30435 12.1537C1.17383 12.0424 1.07927 11.8949 1.03264 11.7299C0.986012 11.5649 0.989408 11.3897 1.0424 11.2266C1.09539 11.0635 1.1956 10.9198 1.33033 10.8137C1.46505 10.7076 1.62824 10.6438 1.79922 10.6305L10.3061 9.9491C10.4653 9.93639 10.6179 9.88001 10.7471 9.78617C10.8763 9.69233 10.9771 9.56467 11.0384 9.41723L14.3145 1.53777Z"
                                                stroke="#A48534" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <div className="review-text">
                                        “TeachLink has really made my teaching experience way more enjoyable!”
                                    </div>
                                    <div className="review-author">Selin P.</div>
                                </div>
                            </div>
                            <div className="review-item">
                                <div className="review-content">
                                    <div className="review-rating">
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M14.1803 1.24528C14.871 -0.415094 17.2266 -0.415094 17.9173 1.24528L21.1271 8.96286L29.4582 9.63195C31.2527 9.77532 31.9804 12.0138 30.6129 13.1855L24.2659 18.6229L26.2038 26.7522C26.6216 28.5035 24.7176 29.8864 23.1821 28.949L16.0488 24.5923L8.91552 28.949C7.38002 29.8864 5.47606 28.5019 5.89385 26.7522L7.83173 18.6229L1.48469 13.1855C0.117228 12.0138 0.844895 9.77532 2.6394 9.63195L10.9706 8.96286L14.1803 1.24528Z"
                                                  fill="#A48534"/>
                                        </svg>
                                        <svg width="30" height="29" viewBox="0 0 30 29" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.3145 1.53777C14.3797 1.37872 14.4907 1.24266 14.6335 1.1469C14.7762 1.05113 14.9442 1 15.1161 1C15.288 1 15.456 1.05113 15.5988 1.1469C15.7416 1.24266 15.8526 1.37872 15.9178 1.53777L19.1938 9.41723C19.2552 9.56467 19.356 9.69233 19.4852 9.78617C19.6144 9.88001 19.767 9.93639 19.9261 9.9491L28.4331 10.6305C29.2023 10.6922 29.5138 11.6526 28.9279 12.1537L22.4468 17.7068C22.3257 17.8104 22.2354 17.9453 22.1859 18.0967C22.1365 18.2482 22.1296 18.4104 22.1662 18.5655L24.1472 26.8674C24.187 27.0339 24.1766 27.2085 24.1172 27.3692C24.0579 27.5298 23.9522 27.6692 23.8137 27.7699C23.6751 27.8705 23.5098 27.9278 23.3387 27.9345C23.1676 27.9413 22.9983 27.8972 22.8522 27.8078L15.5678 23.3601C15.4318 23.277 15.2755 23.233 15.1161 23.233C14.9567 23.233 14.8004 23.277 14.6644 23.3601L7.38005 27.8093C7.23399 27.8987 7.06471 27.9428 6.89359 27.9361C6.72247 27.9293 6.55719 27.872 6.41862 27.7714C6.28005 27.6708 6.17441 27.5313 6.11504 27.3707C6.05567 27.2101 6.04524 27.0355 6.08506 26.8689L8.0661 18.5655C8.10284 18.4104 8.09608 18.2482 8.04658 18.0967C7.99708 17.9452 7.90674 17.8102 7.78551 17.7068L1.30435 12.1537C1.17383 12.0424 1.07927 11.8949 1.03264 11.7299C0.986012 11.5649 0.989408 11.3897 1.0424 11.2266C1.09539 11.0635 1.1956 10.9198 1.33033 10.8137C1.46505 10.7076 1.62824 10.6438 1.79922 10.6305L10.3061 9.9491C10.4653 9.93639 10.6179 9.88001 10.7471 9.78617C10.8763 9.69233 10.9771 9.56467 11.0384 9.41723L14.3145 1.53777Z"
                                                stroke="#A48534" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <div className="review-text">
                                        “The interface is super user-friendly, making it easy to upload materials.”
                                    </div>
                                    <div className="review-author">Idil U.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
            <Footer/>
        </Fragment>
);
}
export default Landing;