// import React from "react";
// import "./payment-popup.style.scss"; // Import styles
//
// const PaymentPopupComponent = ({ course, onClose }) => {
//     return (
//         <div className="payment-popup-overlay">
//             <div className="payment-popup">
//                 <button className="close-btn" onClick={onClose}>✖</button>
//                 <h2>Payment Details</h2>
//                 <p>Course: {course?.name}</p>
//                 <input type="text" placeholder="Card Number" />
//                 <input type="text" placeholder="Name on Card" />
//                 <input type="text" placeholder="Expiration Date (MM/YY)" />
//                 <input type="text" placeholder="Security Code (CVC)" />
//                 <input type="text" placeholder="Country" />
//                 <input type="text" placeholder="Postal Code" />
//                 <button className="pay-btn">Pay</button>
//             </div>
//         </div>
//     );
// };
//
// export default PaymentPopupComponent;
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./payment-popup.style.scss";

const stripePromise = loadStripe("your-publishable-key-here");

const PaymentForm = ({ onClose }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [formData, setFormData] = useState({
        name: "",
        expiry: "",
        cvc: "",
        country: "United States",
        postalCode: "",
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const validate = () => {
        let newErrors = {};
        if (!formData.name) newErrors.name = "Name on card is required";
        if (!formData.postalCode) newErrors.postalCode = "Postal code is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;

        if (!stripe || !elements) return;

        setLoading(true);
        const cardElement = elements.getElement(CardElement);
        const { error } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: { name: formData.name, address: { postal_code: formData.postalCode } },
        });

        setLoading(false);
        if (error) {
            setErrors({ card: error.message });
        } else {
            setSuccess(true);
        }
    };

    return (
        <div className="payment-popup">
            <button className="close-btn" onClick={onClose}>✖</button>
            <h2>Payment Details</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Name on Card"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
                {errors.name && <p className="error">{errors.name}</p>}
                <CardElement className="card-input" />
                {errors.card && <p className="error">{errors.card}</p>}
                <input
                    type="text"
                    placeholder="Postal Code"
                    value={formData.postalCode}
                    onChange={(e) => setFormData({ ...formData, postalCode: e.target.value })}
                />
                {errors.postalCode && <p className="error">{errors.postalCode}</p>}
                <button type="submit" disabled={loading || success}>
                    {loading ? "Processing..." : success ? "Success" : "Pay"}
                </button>
            </form>
        </div>
    );
};

const PaymentPopup = ({ onClose }) => (
    <Elements stripe={stripePromise}>
        <div className="payment-popup-overlay">
            <PaymentForm onClose={onClose} />
        </div>
    </Elements>
);

export default PaymentPopup;

