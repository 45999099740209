import './sign-up.style.scss';
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import eyeOn from '../../../assets/eye-on.svg';
import eyeOff from '../../../assets/eye-off.svg';
import student from '../../../assets/student.svg';
import teacher from '../../../assets/teacher.svg';
import arrowRight from '../../../assets/arrow-right.svg';
import { useNavigate } from 'react-router-dom';
import {useAuth} from "../../../context/auth-context";
import SuccessModal from "../success-modal/success-modal.component";
import ErrorModal from "../error-modal/error-modal.component"; // Import Bootstrap Modal

const SignUpComponent = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [userType, setUserType] = useState('');
    const navigate = useNavigate();
    const [errorHeader, setErrorHeader] = useState('');
    const [successHeader, setSuccessHeader] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);


    // Registration
    const [role, setRole] = useState('');
    const { login } = useAuth();
    const [formData, setFormData] = useState({
        userType: userType,
        name: '',
        surname: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const handleInputChange = (e) => {
        setRole(userType);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        const { name, surname, phone, email, password, confirmPassword } = formData;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!name || !surname || !phone || !email || !password || !confirmPassword) {
            setErrorHeader("Required fields are empty");
            setErrorMessage("Please fill in all required fields!");
            setShowErrorModal(true);
            return false;
        }

        if (!emailRegex.test(email)) {
            setErrorHeader("Invalid Email Address");
            setErrorMessage("Please enter a valid email!");
            setShowErrorModal(true);
            return false;
        }

        if (password.length < 8) {
            setErrorHeader("Password too short");
            setErrorMessage("Password must be at least 8 characters long!");
            setShowErrorModal(true);
            return false;
        }

        if (password !== confirmPassword) {
            setErrorHeader("Password Mismatch");
            setErrorMessage("Passwords do not match!");
            setShowErrorModal(true);
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        if (!validateForm()) return;

        setSuccessHeader("Sign Up Successful");
        setSuccessMessage("Your account has been created successfully! Please log in.");
        setShowSuccessModal(true);
        setTimeout(() => {
            login(role);
            navigate('/');
        }, 2000);
    };

    return (
        <div className="sign-up-container">
            <div className="sign-up-header-wrapper">
                <div className="sign-up-header">
                    <h2 className="sign-up-title">User Sign Up</h2>
                </div>
            </div>

            <div className={`sign-up-options ${userType ? 'hidden' : ''}`}>
                <div className="sign-up-option student-option" onClick={() => setUserType('student')}>
                    <img className="sign-up-option-icon" src={student} alt="Student"/>
                    <div className="sign-up-option-text">
                        <span className="sign-up-option-title">Student</span>
                        <span className="sign-up-option-subtitle">Sign Up as a student</span>
                    </div>
                    <img className="sign-up-option-arrow" src={arrowRight} alt="Arrow"/>
                </div>
                <div className="sign-up-option teacher-option" onClick={() => setUserType('teacher')}>
                    <img className="sign-up-option-icon" src={teacher} alt="Teacher"/>
                    <div className="sign-up-option-text">
                        <span className="sign-up-option-title">Teacher</span>
                        <span className="sign-up-option-subtitle">Sign Up as a teacher</span>
                    </div>
                    <img className="sign-up-option-arrow" src={arrowRight} alt="Arrow"/>
                </div>
            </div>

            <form className={`sign-up-form ${userType ? '' : 'hidden'}`} onSubmit={handleSubmit}>
                <div className="sign-up-row-name">
                    <div className="sign-up-name-wrapper">
                        <label className="sign-up-name-label">Name*</label>
                        <input name="name" className="sign-up-name-input" type="text" placeholder="John" onChange={handleInputChange}/>
                    </div>
                    <div className="sign-up-surname-wrapper">
                        <label className="sign-up-surname-label">Surname*</label>
                        <input name="surname" className="sign-up-surname-input" type="text" placeholder="Doe" onChange={handleInputChange}/>
                    </div>
                </div>

                <div className="sign-up-row-contact">
                    <div className="sign-up-phone-wrapper">
                        <label className="sign-up-phone-label">Phone*</label>
                        <input name="phone" className="sign-up-phone-input" type="text" maxLength={11} placeholder="05528784471" inputMode="numeric" onChange={handleInputChange}/>
                    </div>
                    <div className="sign-up-email-wrapper">
                        <label className="sign-up-email-label">Email*</label>
                        <input name="email" className="sign-up-email-input" type="email" placeholder="john.doe@gmail.com" onChange={handleInputChange}/>
                    </div>
                </div>

                <div className="sign-up-password-wrapper">
                    <label className="sign-up-password-label">Password*</label>
                    <div className="sign-up-password-input-wrapper">
                        <input name="password" className="sign-up-password-input" type={showPassword ? 'text' : 'password'} placeholder="Create password" onChange={handleInputChange}/>
                        <div className="sign-up-password-toggle-btn" type="button" onClick={() => setShowPassword(!showPassword)}>
                            <img src={showPassword ? eyeOff : eyeOn} alt="Toggle Password"/>
                        </div>
                    </div>
                </div>

                <div className="sign-up-confirm-password-wrapper">
                    <label className="sign-up-confirm-password-label">Confirm Password*</label>
                    <div className="sign-up-confirm-password-input-wrapper">
                        <input name="confirmPassword" className="sign-up-confirm-password-input" type={showConfirmPassword ? 'text' : 'password'} placeholder="Rewrite password" onChange={handleInputChange}/>
                        <div className="sign-up-confirm-password-toggle-btn" type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            <img src={showConfirmPassword ? eyeOff : eyeOn} alt="Toggle Confirm Password"/>
                        </div>
                    </div>
                </div>

                <div className="sign-up-button-wrapper">
                    <button type="submit" className="sign-up-submit-button">Sign Up</button>
                </div>
            </form>

            {showSuccessModal && <div className="modal-overlay">
                <SuccessModal header={successHeader} content={successMessage} onClose={() => setShowSuccessModal(false)} />
            </div>}
            {showErrorModal && <div className="modal-overlay">
                <ErrorModal header={errorHeader} content={errorMessage} onClose={() => setShowErrorModal(false)} />
            </div>}
        </div>
    );
};

export default SignUpComponent;

