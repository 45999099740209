import React from 'react';
import { Routes, Route } from 'react-router-dom'
import Landing from "../pages/guest/landing/landing.component";
import Courses from "../pages/guest/courses/courses.component";
import CourseDetails from "../pages/guest/course-details/course-details.component";
import AboutUs from "../pages/guest/about-us/about-us.component";
import ContactUs from "../pages/guest/contact-us/contact-us.component";
import Authentication from "../pages/guest/authentication/authentication.component";
import ForgotPassword from "../pages/guest/forgot-password/forgot-password.component";
import ResetPassword from "../pages/guest/reset-password/reset-password.component";

const GuestRoutes = () => {
    return(
        <Routes>
            <Route path="/" element={<Landing/>}></Route>
            <Route path="/courses" element={<Courses/>}></Route>
            <Route path="/course-details" element={<CourseDetails/>}></Route>
            <Route path={"/about-us"} element={<AboutUs/>}/>
            <Route path={"/contact-us"} element={<ContactUs/>}/>
            <Route path={"/authentication"} element={<Authentication/>}/>
            <Route path={"/authentication/forgot-password"} element={<ForgotPassword/>}/>
            <Route path={"/authentication/reset-password"} element={<ResetPassword/>}/>
        </Routes>
    )
};

export default GuestRoutes;