import Footer from '../../../components/layout/footer/footer.component';
import Navigation from '../../../components/layout/navigation/navigation.component';
import './courses.style.scss';
import {Fragment} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SearchComponent from "../../../components/common/search/search.component";
import FilteringComponent from "../../../components/common/filtering/filtering.component";
import CourseCard from "../../../components/common/course-card/course-card.component";
import Pagination from "../../../components/common/pagination/pagination.component";

const courses = [
    {
        title: "Build Responsive Real-World Websites with HTML and CSS",
        author: "Jonas Schmedtmann",
        rating: 5,
        reviews: "25,000",
        price: "49.99",
        image: "https://img-c.udemycdn.com/course/240x135/437398_46c3_10.jpg",
        link: "https://www.udemy.com/course-dashboard-redirect/?course_id=437398"
    },
    {
        title: "100 Days of Code: The Complete Python Pro Bootcamp",
        author: "Dr. Angela Yu",
        rating: 5,
        reviews: "120,000",
        price: "59.99",
        image: "https://img-c.udemycdn.com/course/240x135/2776760_f176_10.jpg",
        link: "https://www.udemy.com/course-dashboard-redirect/?course_id=2776760"
    },
    {
        title: "Java 17 Masterclass: Start Coding in 2024",
        author: "Tim Buchalka",
        rating: 4.7,
        reviews: "150,000",
        price: "39.99",
        image: "https://img-c.udemycdn.com/course/240x135/533682_c10c_4.jpg",
        link: "https://www.udemy.com/course/java-the-complete-java-developer-course/"
    },
    {
        title: "Go - The Complete Guide",
        author: "Maximilian Schwarzmüller",
        rating: 4.6,
        reviews: "50,000",
        price: "44.99",
        image: "https://img-c.udemycdn.com/course/240x135/5652172_fc8b.jpg",
        link: "https://www.udemy.com/course/go-the-complete-guide/"
    },
    {
        title: "The Complete Spring Boot Development Bootcamp",
        author: "Learn The Part Inc.",
        rating: 4.9,
        reviews: "75,000",
        price: "54.99",
        image: "https://img-c.udemycdn.com/course/240x135/4695284_83ea_6.jpg",
        link: "https://www.udemy.com/course-dashboard-redirect/?course_id=4695284"
    },
    {
        title: "Complete C# Unity Game Developer 2D",
        author: "GameDev.tv Team",
        rating: 4.8,
        reviews: "80,000",
        price: "45.99",
        image: "https://img-c.udemycdn.com/course/240x135/258316_55e9_12.jpg",
        link: "https://www.udemy.com/course/unitycourse/?couponCode=KEEPLEARNING"
    },
    {
        title: "Figma UI UX Design Essentials",
        author: "Daniel Walter Scott",
        rating: 4.7,
        reviews: "30,000",
        price: "35.99",
        image: "https://img-c.udemycdn.com/course/240x135/4359576_b9e1_2.jpg",
        link: "https://www.udemy.com/course/figma-ux-ui-design-user-experience-tutorial-course/"
    },
    {
        title: "Master the Fundamentals of Math",
        author: "Krista King",
        rating: 4.9,
        reviews: "60,000",
        price: "29.99",
        image: "https://img-c.udemycdn.com/course/240x135/221578_40f0_7.jpg",
        link: "https://www.udemy.com/course/fundamentals-of-math/"
    },
    {
        title: "Fundamentals of Database Engineering",
        author: "Hussein Nasser",
        rating: 4.8,
        reviews: "55,000",
        price: "42.99",
        image: "https://img-c.udemycdn.com/course/240x135/2722880_af31_7.jpg",
        link: "https://www.udemy.com/course/fundamentals-of-database-engineering/"
    }
];
const Courses = () =>{
    return(
        <Fragment>
            <Navigation></Navigation>
            <div className='courses-container'>
                <SearchComponent></SearchComponent>
                <div className='courses-wrap'>
                    <FilteringComponent></FilteringComponent>
                    <div className='courses-wrap-container'>
                        <div className="grid container-fluid col-md-10 col-lg-10">
                            <div className="courses-cards row text-center gx-5">
                                {courses.map((course, index) => (
                                    <CourseCard key={index} course={course}/>
                                ))}
                            </div>
                        </div>
                        <div className='courses-pagination'>
                            <Pagination></Pagination>
                        </div>
                    </div>
                </div>

            </div>
            <Footer></Footer>
        </Fragment>
    );
}
export default Courses;