import './course-details.style.scss';
import {Fragment, useState} from "react";
import Navigation from "../../../components/layout/navigation/navigation.component";
import Footer from "../../../components/layout/footer/footer.component";
import CourseDescriptionComponent from "../../../components/common/course-description/course-description.component";
import GroupCardComponent from "../../../components/common/group-card/group-card.component";
import star from '../../../assets/star.svg';
import arrowBottom from '../../../assets/arrow-bottom.svg';
import ReviewCardComponent from "../../../components/common/review-card/review-card.component";
import Pagination from "../../../components/common/pagination/pagination.component";
const CourseDetails = () =>{
    const [activeSort, setActiveSort] = useState("");
    const [hiddenSort, setHiddenSort] = useState(true);
    const handleSortingClick = (sort) =>{
        setActiveSort(sort);
    }
    return(
        <Fragment>
            <Navigation></Navigation>
            <CourseDescriptionComponent></CourseDescriptionComponent>
            <div className='course-details-groups'>
                <h1 className='course-details-groups-title'>Groups</h1>
                <div className='course-details-groups-container'>
                    <GroupCardComponent></GroupCardComponent>
                    <GroupCardComponent></GroupCardComponent>
                    <GroupCardComponent></GroupCardComponent>
                </div>
            </div>
            <div className='course-details-review'>
                <h1 className='course-details-review-title'>Reviews</h1>
                <div className='course-details-review-header'>
                    <div className='course-details-review-summary'>
                        <span className='course-details-review-icon'>
                            <img src={star} alt=""/>
                        </span>
                        <span className='course-details-review-count'>2.5K Reviews</span>
                        <div className='course-details-review-sort' onClick={()=> {setHiddenSort(!hiddenSort)}}>
                            <span className='course-details-review-sort-label'>Sort by</span>
                            <img className='course-details-review-sort-icon' src={arrowBottom} alt=""/>
                        </div>
                    </div>
                    <div className={`${hiddenSort === true ? 'hidden' : 'course-details-review-filters-container' }`}>
                        <div className=' course-details-review-filters'>
                            <span className={`course-details-review-filter ${activeSort === 'htl' ? 'c-d-f-active' : ''}`} onClick={()=> handleSortingClick('htl')}
                            >Score: High to Low</span>
                            <span className={`course-details-review-filter ${activeSort === 'lth' ? 'c-d-f-active' : ''}`} onClick={()=> handleSortingClick('lth')}>Score: Low to High</span>
                        </div>
                    </div>
                </div>
                <div className='course-details-reviews-list'>
                    <ReviewCardComponent
                        name="Ali Raza"
                        date="06/07/22"
                        rating={5}
                        review="Clear and engaging lessons! This course is ideal for beginners who want to build a solid foundation in JavaScript. The instructor explains concepts in an easy-to-understand manner, making it perfect for anyone new to coding and looking to master the basics with confidence."
                        avatar={'https://s3-alpha-sig.figma.com/img/1b05/19a5/480e1ddbfea6ad4c0aaf7a2e8f7c3b98?Expires=1740960000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=jjOJ8pk2iyzUZLdWssFLECWOP54SQDd7cR9g~HamL1q5XbNePmtqKwtjkDRDdHeNSC68EhrtHVSTXdz4NwL2Msmaz4Smvm1YNfBeHNtPWvjeSr9GHEcz-7H24CFiM2Vd8EiFuZK9CadxoymFVDv8q3Zm7p7syog3liXJ6~P6c73dMzrWfvM0mkO4VGY29u2Tc7XYyg9C1Hh79Bn35rF-HQEi88DIp-kji3nS7V142TwY8Iu9F89HDoxwdFaZRHXgwEO~~ldfSJBveuRHeSU2lykqcRc~oPyl6~DqCkzzJTlLsbOl-IwB4i39GXIuI1bgvfFz961ayNPuIopY1amAuw__'}
                    ></ReviewCardComponent>
                    <ReviewCardComponent
                        name="Ahmet Naz"
                        date="06/07/22"
                        rating={5}
                        review="This course offers clear and engaging lessons that break down JavaScript fundamentals step by step. It’s perfect for beginners eager to dive into coding and start creating interactive web features with confidence. Highly recommended for its practical examples and approachable teaching style!"
                    ></ReviewCardComponent>
                    <ReviewCardComponent
                        name="Ahmet Naz"
                        date="21/04/22"
                        rating={5}
                        review="An excellent introduction to JavaScript! The lessons are well-structured and easy to follow, making it simple for beginners to grasp even complex concepts. If you're looking to build a strong foundation in coding and enhance your web development skills, this course is a fantastic starting point."
                    ></ReviewCardComponent>
                </div>
                <div className='course-details-review-pagination'>
                    <Pagination></Pagination>
                </div>
            </div>
            <Footer></Footer>
        </Fragment>
    )
}
export default CourseDetails;