import './search.style.scss';
import search from '../../../assets/search.svg';
const SearchComponent = () =>{
    return(
        <div className='search-div'>
            <div className='search-container'>
                <img className='search-logo' src={search} alt=""/>
                <input className='search-input' placeholder='Search Courses'/>
            </div>
        </div>

    )
}
export default SearchComponent;