import './review-card.style.scss';
import star from "../../../assets/star.svg";
import user from "../../../assets/user.svg";

const ReviewCardComponent = ({ name, date, rating, review, avatar = user }) => {
    if (avatar === user){
        console.log(avatar);

    }
    return (
        <div className="review-card">
            <div className="review-avatar">
                <img className={`${avatar === user ? `avatar-svg-image` : `avatar-image`}`}
                     src={avatar} alt=""/>
            </div>
            <div className="rewiew-content">
                <div className="review-header">
                    <span className="review-name">{name}</span>
                    <span className="review-date">{date}</span>
                </div>
                <div className="review-stars">
                    {Array.from({length: rating}).map((_, index) => (
                        <img key={index} height={'23px'} width={'25px'} className="star-icon" src={star} alt="Star"/>
                    ))}
                </div>
                <p className="review-text">{review}</p>
            </div>
        </div>
    );
};
export default ReviewCardComponent;