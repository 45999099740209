import './success-modal.style.scss';

const SuccessModal = ({ header, content, onClose  }) => {
    return <div className="success-message-container">
        <div className="success-message-close">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClose}>
                <path d="M1 13L13 1M1 1L13 13" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </div>
        <div className="success-message-wrapper">
            <div className="success-message-icon">
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.375 17.5312L15.4688 20.625L20.625 13.4062M28.875 16.5C28.875 18.1251 28.5549 19.7343 27.933 21.2357C27.3111 22.7371 26.3996 24.1013 25.2504 25.2504C24.1013 26.3996 22.7371 27.3111 21.2357 27.933C19.7343 28.5549 18.1251 28.875 16.5 28.875C14.8749 28.875 13.2657 28.5549 11.7643 27.933C10.2629 27.3111 8.89868 26.3996 7.74955 25.2504C6.60043 24.1013 5.68889 22.7371 5.06699 21.2357C4.44509 19.7343 4.125 18.1251 4.125 16.5C4.125 13.2179 5.42879 10.0703 7.74955 7.74955C10.0703 5.42879 13.2179 4.125 16.5 4.125C19.7821 4.125 22.9297 5.42879 25.2504 7.74955C27.5712 10.0703 28.875 13.2179 28.875 16.5Z" stroke="#28A745" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>


            </div>
            <div className="success-message-content">
                <h3>{header}</h3>
                <p>{content}</p>
            </div>
        </div>
    </div>;
}

export default SuccessModal;