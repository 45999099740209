import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import AppRoutes from "./routes/app-routes";

function App() {
  return (
      <AppRoutes></AppRoutes>
  );
}

export default App;
